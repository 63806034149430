import gql from "graphql-tag";

export const createArea = gql`
mutation createArea($input: CreateAreaInput) {
  createArea(input: $input) {
    id
    name
    contactListId
    owner {
      id
      firstName
      lastName
      nickName
    }
    region {
      id
      name
    }
    facilities {
      id
      facilityName
    }
    updater
    deletedAt
    version
  }
}`
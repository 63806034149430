<div id="facility-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  <div *ngIf="ifDeviceMatches([DeviceSize.XL, DeviceSize.LG, DeviceSize.MD])">
    <div id="facility-action-buttons" *ngIf="elements">
      <div class="buttons">
        <div id="business-units-wrapper">
          <app-business-unit-filter
            (selectedBusinessUnitsChanged)="handleBusinessUnitChange($event)"
            #buFilter
          ></app-business-unit-filter>
        </div>
        <div *ngIf="!isMobileDevice()">
          <button
            *ngIf="canCreate"
            pButton
            class="width-eighty"
            label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
            icon="fa fa-fw fa-plus-circle"
            (click)="newFacility()"
          ></button>
          <button
            pButton
            class="refresh-button width-twenty"
            icon="fa fa-fw fa-refresh"
            (click)="refresh()"
          ></button>
        </div>
        <div *ngIf="!isMobileDevice()">
          <button
            pButton
            label="{{ 'COMMON.LABEL.BUTTONS.CLEAR' | translate }}"
            icon="fa fa-fw fa-eraser"
            (click)="clearScreen()"
          ></button>
        </div>
        <div>
          <app-progress-spinner
            [enabled]="facilitiesLoading"
          ></app-progress-spinner>
        </div>
      </div>
    </div>
    <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
    <div [style.display]="!elements ? 'none' : ''">
      <app-general-grid
        [values]="elements"
        [columns]="columns"
        [loading]="loading"
        [deviceSize]="getDeviceSize()"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [filterable]="true"
        [screenName]="screenName"
        [lazy]="true"
        (lazyLoad)="lazyLoad($event)"
        [includeExportCSV]="false"
        (changeViewEvent)="changeViewEvent($event)"
        scrollHeight="calc(100vh - 256px)"
        [usePaginator]="true"
        [rowsPerPageOptions]="[50, 100, 200]"
        #grid
      >
      </app-general-grid>
    </div>
  </div>

  <p-panel
    [showHeader]="true"
    header="Filters"
    [toggleable]="true"
    [(collapsed)]="mobileFilterCollapsed"
    *ngIf="ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])"
  >
    <div class="ui-g ui-fluid">
      <div class="ui-g-12">
        <app-facility-mobile-filter
          (mobileFilterEvent)="mobileFilter($event)"
        ></app-facility-mobile-filter>
      </div>
    </div>
  </p-panel>
  <div *ngIf="ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])">
    <div class="ui-g ui-fluid" id="facility-action-buttons" *ngIf="elements">
      <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
      <div [style.display]="!elements ? 'none' : ''">
        <app-general-grid
          [values]="elements"
          [columns]="columns"
          [loading]="loading"
          [deviceSize]="getDeviceSize()"
          [rows]="rows"
          [totalRecords]="totalRecords"
          [filterable]="true"
          [screenName]="screenName"
          [lazy]="true"
          (lazyLoad)="lazyLoad($event)"
          [includeExportCSV]="false"
          (changeViewEvent)="changeViewEvent($event)"
          scrollHeight="calc(100vh - 175px)"
          [usePaginator]="true"
          [rowsPerPageOptions]="[50, 100, 200]"
          #grid
        >
        </app-general-grid>
      </div>
    </div>
  </div>
</div>

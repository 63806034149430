import { Column } from '../../model/common/column';

export const FACILITY_TABLE_DEFINITION: Column[] = [
  {
    field: 'facilityName',
    header: 'LOCATION.LABEL.FACILITY_NAME',
    mobileHeader: 'LOCATION.LABEL.FACILITY_NAME',
    formatPattern: 'text',
    width: '15%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'facilityUid',
    header: 'LOCATION.LABEL.FACILITY_UID',
    mobileHeader: 'LOCATION.LABEL.FACILITY_UID',
    formatPattern: 'hyperlink',
    width: '10%',
    sortable: true,
    filterable: true,
    matchMode: 'startsWith',
  },
  {
    field: 'scadaId',
    header: 'LOCATION.LABEL.SCADA_ID',
    mobileHeader: 'LOCATION.LABEL.SCADA_ID',
    formatPattern: 'text',
    width: '10%',
    sortable: true,
    filterable: true,
    matchMode: 'startsWith',
  },
  {
    field: 'aor',
    header: 'LOCATION.LABEL.AOR',
    mobileHeader: 'LOCATION.LABEL.AOR',
    formatPattern: 'text',
    width: '10%',
    sortable: true,
    filterable: true,
    matchMode: 'startsWith',
  },
  {
    field: 'state',
    header: 'LOCATION.LABEL.STATE',
    mobileHeader: 'LOCATION.LABEL.STATE',
    formatPattern: 'text',
    width: '10%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'county',
    header: 'LOCATION.LABEL.COUNTY',
    mobileHeader: 'LOCATION.LABEL.COUNTY',
    formatPattern: 'text',
    width: '10%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'township',
    header: 'LOCATION.LABEL.TOWNSHIP',
    mobileHeader: 'LOCATION.LABEL.TOWNSHIP',
    formatPattern: 'text',
    width: '10%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'facilityType',
    header: 'LOCATION.LABEL.FACILITY_TYPE',
    mobileHeader: 'LOCATION.LABEL.FACILITY_TYPE',
    formatPattern: 'text',
    width: '10%',
    sortable: true,
    filterable: true,
    matchMode: 'startsWith',
  },
  {
    field: 'company',
    header: 'LOCATION.LABEL.COMPANY',
    mobileHeader: 'LOCATION.LABEL.COMPANY',
    formatPattern: 'text',
    width: '15%',
    sortable: true,
    filterable: true,
    matchMode: 'startsWith',
  },
];

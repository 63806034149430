import { gql } from '@apollo/client/core';

export const getRegion = gql`
query getRegion($id: String) {
  getRegion(id: $id) {
    id
    name
    contactListId
    owner {
      id
      firstName
      lastName
      nickName
    }
    areas {
      id
      name
    }
    updater
    deletedAt
    version
  }
}`
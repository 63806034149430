import gql from "graphql-tag";

export const updateArea = gql`
mutation updateArea($id: String, $input: UpdateAreaInput) {
  updateArea(id: $id, input: $input) {
    id
    name
    contactListId
    owner {
      id
      firstName
      lastName
      nickName
    }
    region {
      id
      name
    }
    facilities {
      id
      facilityName
    }
    updater
    deletedAt
    version
  }
}`
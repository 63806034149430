import {Column} from '../../model/common/column';

export const LOCATION_TABLE_DEFINITION: Column[] = [
    {
      field: 'state',
      header: 'LOCATION.LABEL.STATE',
      mobileHeader: 'LOCATION.LABEL.STATE',
      formatPattern: 'text',
      width: '25%',
      sortable: true,
      filterable: true,
      matchMode: 'startsWith'
    },
    {
      field: 'county',
      header: 'LOCATION.LABEL.COUNTY',
      mobileHeader: 'LOCATION.LABEL.COUNTY',
      formatPattern: 'text',
      width: '25%',
      sortable: true,
      filterable: true,
      matchMode: 'startsWith'
    },
    {
      field: 'township',
      header: 'LOCATION.LABEL.TOWNSHIP',
      mobileHeader: 'LOCATION.LABEL.TOWNSHIP',
      formatPattern: 'text',
      width: '25%',
      sortable: true,
      filterable: true,
      matchMode: 'startsWith'
    },
    {
      field: "businessUnitString",
      header: "COMMON.LABEL.BUSINESS_UNITS",
      mobileHeader: "COMMON.LABEL.BUSINESS_UNITS",
      formatPattern: "hyperlink",
      type: "input",
      width: "25%",
      sortable: true,
      filterable: true,
      matchMode: "contains",
    },
];

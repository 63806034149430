import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  REGIONS_CONTAINER,
  REGION_DETAIL_CONTAINER,
} from 'src/app/constants/common.constants';
import {
  REGION_TAB_PREFIX,
  NEW_REGION,
} from 'src/app/constants/location-constants';
import { TabService } from 'src/app/core/services/tab.service';
import { BreadCrumb } from 'src/app/model/common/bread-crumb';
import { ContactList } from 'src/app/model/contacts/contact-list';
import { LoadingService } from 'src/app/services/loading.service';
import { RegionApiService } from 'src/app/services/region-api.service';
import { dynamicSort } from 'src/app/core/functions/common-functions';
import { isNullOrUndefined } from 'util';
import { v4 as uuid } from 'uuid';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { TranslateService } from '@ngx-translate/core';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { DeviceService } from 'src/app/services/device.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { ContactListApiService } from 'src/app/services/contact-list-api.service';
import { ContactListBuilderService } from 'src/app/services/contact-list-builder.service';
import { BaseContainer } from 'src/app/core/containers/base-container';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { ContactBuilderService } from 'src/app/services/contact-builder.service';
import { Contact } from 'src/app/model/contacts/contact';
import { Region } from 'src/app/model/locations/region';
import { RegionBuilderService } from 'src/app/services/region-builder.service';

@Component({
  selector: 'app-region-details-container',
  templateUrl: './region-details-container.component.html',
  styleUrls: ['./region-details-container.component.scss'],
})
export class RegionDetailsContainerComponent
  extends BaseContainer
  implements OnInit, OnDestroy
{
  private lId: string;
  @Input() set id(id) {
    this.lId = id;
  }
  get id() {
    return this.lId;
  }

  @Input() index: number;

  @Output() changesEvent = new EventEmitter<any>();

  regionBreadCrumb: BreadCrumb;
  backToSearchResult: BreadCrumb;
  availableRegions: SelectItem[];
  canCreate = false;
  canEdit = false;
  canDelete = false;
  canCreateContactList = false;
  tabClosingSub: Subscription;
  breadCrumbSub: Subscription;
  valueSub: Subscription;
  region: Region;
  loading = true;
  saving = false;
  form: UntypedFormGroup;
  contactList: ContactList;
  createContactListDialog = false;
  displayDialog = false;
  tempContactList: ContactList;
  actions: SelectItem[] = [];
  selectedAction: any;
  isEditing = false;
  tabIndex = 0;
  allContacts: Contact[];
  filteredContacts: SelectItem[];

  constructor(
    protected _translateService: TranslateService,
    private _breadCrumbService: BreadCrumbBuilderService,
    protected _deviceService: DeviceService,
    private _fb: UntypedFormBuilder,
    private _logAndMessage: LogAndMessageService,
    protected _confirmationService: ConfirmationService,
    private _contactListApi: ContactListApiService,
    private _contactListBuilder: ContactListBuilderService,
    private _authApi: AuthApiService,
    private _cdRef: ChangeDetectorRef,
    private _loader: LoadingService,
    protected _regionApi: RegionApiService,
    private _regionBuilder: RegionBuilderService,
    protected _contactApi: ContactApiService,
    private _contactBuilder: ContactBuilderService
  ) {
    super(_deviceService);
  }

  ngOnInit() {
    this.setupTabClosingSubscription();
    this.setupBreadCrumbSub();

    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.subscribe((event) => {
        this.initialize();
      });
    }
  }

  setupBreadCrumbSub(): void {
    this.breadCrumbSub =
      this._breadCrumbService.breadCrumbSelectedEvent.subscribe(
        (breadCrumb) => {
          this._breadCrumbService.removeBreadCrumb(this.regionBreadCrumb);
          const tab = TabService.getInstance().buildNewTab(
            REGIONS_CONTAINER,
            true
          );
          TabService.getInstance().setMobileTab(tab);
        }
      );
  }

  initialize() {
    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      'CreateRegions',
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList(['EditRegions']);
    this.canDelete = this._authApi.doesUserHaveAllClaimsFromList([
      'DeleteRegions',
    ]);
    this.canCreateContactList = this._authApi.doesUserHaveAllClaimsFromList([
      'CreateContactLists',
    ]);
    var observables = [];

    observables.push(
      this._regionApi.getAvailableRegions(),
      this._contactApi.findContacts()
    );
    if (this.id) {
      observables.push(this._regionApi.getRegion(this.id));
    } else {
      // if there's no id, we need an empty region as our base
      this.region = {
        id: null,
        name: null,
        contactListId: null,
        owner: null,
        ownerId: null,
        areas: [],
      };
    }

    forkJoin(observables)
      .pipe(take(1))
      .subscribe((responses: any) => {
        const contactDataClone = { ...responses[1].data };
        this.allContacts = contactDataClone.getContacts
          .map((c) => this._contactBuilder.buildContact(c))
          .sort(dynamicSort('firstName', 1));

        if (responses.length > 2) {
          const regionDataClone = Object.assign({}, responses[2].data);

          this.region = this._regionBuilder.buildRegion(
            regionDataClone.getRegion,
            []
          );
        }

        this.setupScreen();
      });
  }

  setupScreen() {
    this.findContactList();
    this.buildForm();
    this.updateForm();

    if (this._deviceService.isMobile()) {
      this._translateService
        .get('LOCATION.SCREEN.REGION')
        .subscribe((label) => {
          this._breadCrumbService.resetAndAddBreadCrumb(
            new BreadCrumb(label, null, true)
          );
          if (this.region) {
            this.regionBreadCrumb = new BreadCrumb(
              this.region.name,
              null,
              false
            );
          }
          this._breadCrumbService.addBreadCrumb(this.regionBreadCrumb);
        });
    } else {
      if (isNullOrUndefined(this.region.id)) {
        TabService.getInstance().updateActiveTabLabel(NEW_REGION);
      } else {
        TabService.getInstance().updateActiveTabLabel(
          REGION_TAB_PREFIX + this.region.name
        );
      }
    }

    this.updateActionsList();
    this._cdRef.markForCheck();
    this.loading = false;
  }

  findContactList() {
    if (this.region.contactListId) {
      // get Contact List
      this._contactListApi
        .getContactList(this.region.contactListId)
        .pipe(take(1))
        .subscribe(({ data }) => {
          const clone = Object.assign({}, data);
          this.contactList = this._contactListBuilder.buildContactList(
            clone.getContactList
          );
          this.contactList = { ...this.contactList };
          this._cdRef.detectChanges();
          this._cdRef.markForCheck();
        });
    } else {
      console.log('No contact list to find.');
    }
  }

  findOwner() {
    if (this.region.ownerId) {
      return this.allContacts
        .filter((c) => c.id == this.region.ownerId)
        .map((c) => ({ label: c.firstName + ' ' + c.lastName, value: c.id }))
        .shift();
    }
    return null;
  }

  buildForm() {
    this.form = this._fb.group({
      id: null,
      name: [null, Validators.required],
      contactListId: null,
      owner: null,
      areas: null,
      updater: [{ value: null, disabled: true }],
      version: null,
    });

    this.valueSub = this.form.valueChanges.subscribe((changes) => {
      if (this.form.dirty) {
        this.changesEvent.emit({
          isDirty: true,
          index: this.index,
          id: this.id,
        });
      }
    });
  }

  updateForm() {
    if (!isNullOrUndefined(this.region)) {
      this.form.patchValue({
        id: this.region.id,
        name: this.region.name,
        contactListId: this.region.contactListId,
        owner: this.findOwner(),
        updater: this.region.updater,
        version: this.region.version,
      });

      if (isNullOrUndefined(this.region.id)) {
        this.isEditing = true;
      }

      if (!this.canEdit && !this.canCreate) {
        this.form.disable();
      } else if (this.region.id && this.isEditing === false) {
        this.form.disable();
      }
    }
  }

  updateActionsList() {
    if (this.canEdit || this.canDelete) {
      const selectAction$ = this._translateService.get(
        'COMMON.LABEL.SELECT_ACTION'
      );
      const removeList$ = this._translateService.get(
        'LOCATION.LABEL.BUTTONS.REMOVE_CONTACT_LIST'
      );
      const delete$ = this._translateService.get('COMMON.LABEL.BUTTONS.DELETE');

      let select,
        removeList,
        del = null;
      forkJoin([selectAction$, removeList$, delete$])
        .pipe(take(1))
        .subscribe((messages) => {
          select = messages[0];
          removeList = messages[1];
          del = messages[2];

          this.actions = [
            { label: select, value: null },
            { label: removeList, value: 'remove-list' },
          ];

          if (this.canDelete) {
            this.actions.push({ label: del, value: 'delete-region' });
          }

          // default actions here: Send to Day Crew, etc.
          this.actions = [...this.actions];
        });
    }
  }

  actionEvent($event, object?: any) {
    if ($event.value) {
      this.selectedAction = null;
      if ($event.value === 'remove-list') {
        this.clearContactList();
      } else if ($event.value === 'delete-region') {
        let confHeader = null,
          ok = null,
          cancel = null,
          message = null;
        const confHeader$ = this._translateService.get(
          'LOCATION.MESSAGES.HEADERS.DELETE_REGION'
        );
        const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
        const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
        const message$ = this._translateService.get(
          'LOCATION.MESSAGES.CONFIRMATION.DELETE_REGION',
          { name: this.region.name }
        );

        forkJoin([confHeader$, ok$, cancel$, message$]).subscribe(
          (messages) => {
            confHeader = messages[0];
            ok = messages[1];
            cancel = messages[2];
            message = messages[3];
          }
        );

        this._confirmationService.confirm({
          header: confHeader,
          icon: 'fa fa-question-circle',
          acceptVisible: true,
          rejectVisible: true,
          acceptLabel: ok,
          rejectLabel: cancel,
          message: message,
          accept: () => {
            this.saving = true;
            if (
              !isNullOrUndefined(this.region) &&
              !isNullOrUndefined(this.region.id)
            ) {
              this._regionApi
                .deleteRegion(this.region.id)
                .pipe(take(1))
                .subscribe(
                  (result) => {
                    this.saving = false;
                    this.displayDialog = false;
                  },
                  (error) => {
                    console.log('Error deleting region', error);
                  },
                  () => {
                    const index = TabService.getInstance().getActiveIndex();
                    TabService.getInstance().closeTab(index);
                  }
                );
            }
          },
          reject: () => {},
        });
      }

      if (!isNullOrUndefined(object)) {
        object.clear(null);
      }
    }
    this._cdRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.filteredContacts = null;
    this.region = null;
    this.contactList = null;
    if (this.breadCrumbSub) {
      this.breadCrumbSub.unsubscribe();
    }
  }

  saveRegion(fromAction = false) {
    if (this.form.valid || fromAction === true) {
      const regionForm = Object.assign({}, this.form.getRawValue());
      const region = Object.assign(
        {},
        {
          id: regionForm.id,
          name: regionForm.name,
          ownerId: regionForm.owner?.value,
          contactListId: regionForm.contactListId,
        }
      );

      if (!regionForm.id) {
        this.createRegion(region);
      } else {
        this.updateRegion(region);
      }
    } else {
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'LOCATION.MESSAGES.HEADERS.SAVE_REGION',
        bodyKey: 'LOCATION.MESSAGES.ERROR.SAVING_REGION_ERROR',
      });
    }
  }

  createRegion(region) {
    if (!this.canCreate) {
      return;
    }
    this.saving = true;

    region.id = uuid();
    region.updater = null;
    region.updatedAt = null;
    region.createdAt = null;
    const regionInput = Object.assign(
      {},
      {
        id: region.id,
        name: region.name,
        ownerId: region.ownerId,
        contactListId: region.contactListId,
      }
    );

    this._regionApi
      .createRegion(regionInput)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
            headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
          });
          const clone = Object.assign({}, data);
          this.region = Object.assign(
            {},
            this._regionBuilder.buildRegion(
              Object.assign({}, clone.createRegion),
              []
            )
          );
          this.saving = false;
          this.findContactList();
          this.updateForm();
          this.form.markAsPristine();
          this._cdRef.markForCheck();
        },
        (error) => {
          console.log(error);
          this.saving = false;
          this.setEditing(false);
          this._logAndMessage.translateToErrorMessage({
            headerKey: 'LOCATION.MESSAGES.HEADERS.SAVE_REGION',
            bodyKey: 'LOCATION.MESSAGES.ERROR.SAVING_REGION_ERROR',
          });
        },
        () => {
          if (!this._deviceService.isMobile()) {
            const oldTab = TabService.getInstance().getActiveTab();
            const tab = TabService.getInstance().buildNewTab(
              REGION_DETAIL_CONTAINER,
              true,
              null,
              this.region.id
            );
            TabService.getInstance().replaceTab(oldTab, tab);
            this.changesEvent.emit({
              isDirty: false,
              index: this.index,
              id: this.id,
            });
          }
        }
      );
  }

  updateRegion(region) {
    if (!this.canEdit) {
      return;
    }

    this.saving = true;

    const regionInput = Object.assign(
      {},
      {
        id: region.id,
        name: region.name,
        ownerId: region.ownerId,
        contactListId: region.contactListId,
      }
    );

    // If the contactlistid is pristine, that means that we haven't
    // removed it or changed it, and so it won't come in from getDirtyValues
    // but our lambda will remove the list if we don't send it back
    // so we need to re-send it every time we make other changes
    // to the list
    if (this.form.controls['contactListId'].pristine) {
      regionInput['contactListId'] = this.form.controls['contactListId'].value;
    }

    if (!isNullOrUndefined(this.region)) {
      const update = this._regionBuilder.buildUpdateStatement(
        this.region,
        regionInput
      );

      this._regionApi
        .updateRegion(this.region.id, update)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            this._logAndMessage.translateToSuccessMessage({
              bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
              headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
            });
            const clone = Object.assign({}, data);
            this.region = Object.assign(
              {},
              this._regionBuilder.buildRegion(clone.updateRegion, [])
            );
            if (regionInput['contactListId']) {
              this.findContactList();
            }
            this._cdRef.markForCheck();
            this.updateForm();
            this.form.markAsPristine();
            this.saving = false;
            this.setEditing(false);
          },
          (error) => {
            this.saving = false;
            this.setEditing(false);
            this._logAndMessage.translateToErrorMessage({
              headerKey: 'LOCATION.MESSAGES.HEADERS.SAVE_REGION',
              bodyKey: 'LOCATION.MESSAGES.ERROR.SAVING_REGION_ERROR',
            });
          },
          () => {
            this.changesEvent.emit({
              isDirty: false,
              index: this.index,
              id: this.id,
            });
          }
        );
    }
  }

  openCreateContactList() {
    this.createContactListDialog = true;
  }

  setTempList(list) {
    if (this.canEdit === true) {
      this.setEditing(true);
      this.tempContactList = list;
      this.form.patchValue({
        contactListId: !isNullOrUndefined(this.tempContactList)
          ? this.tempContactList.id
          : null,
      });
      this.form.controls['contactListId'].markAsDirty();
      this.createContactListDialog = false;
    }
  }

  clearContactList() {
    if (this.canEdit === true) {
      this.setEditing(true);
      this.form.patchValue({
        contactListId: null,
      });
      this.form.controls['contactListId'].markAsDirty();
      this.region.contactListId = null;
      this.contactList = null;
      this.tempContactList = null;
    }
  }

  setEditing(editing: boolean) {
    if (this.canEdit || this.canCreate) {
      this.isEditing = editing;

      if (editing) {
        this.form.enable();
        // always disabled
        this.form.controls['updater'].disable();
      } else {
        this.form.disable();
      }
    } else {
      this.isEditing = false;
      this.form.disable();
      this._logAndMessage.translateToWarnMessage({
        bodyKey: 'COMMON.MESSAGES.WARN.ADMIN_RIGHTS',
        headerKey: 'COMMON.MESSAGES.HEADERS.AUTHORIZATION_MESSAGE',
      });
    }
  }

  tabChanged($event): void {
    if (!isNullOrUndefined($event)) {
      this.tabIndex = $event.index;
    }
  }

  setupTabClosingSubscription(): void {
    this.tabClosingSub = TabService.getInstance().attemptCloseEvent.subscribe(
      (closingTab) => {
        if (!isNullOrUndefined(this.id)) {
          if (this.id === closingTab.id && (!this.form || !this.form.dirty)) {
            TabService.getInstance().closeTab(closingTab.index);
          } else if (
            this.id === closingTab.id &&
            this.form &&
            this.form.dirty
          ) {
            const unsaved$ = this._translateService.get(
              'COMMON.MESSAGES.CONFIRMATION.UNSAVED_CHANGES'
            );
            const yes$ = this._translateService.get(
              'COMMON.LABEL.BUTTONS.LEAVE'
            );
            const no$ = this._translateService.get(
              'COMMON.LABEL.BUTTONS.CANCEL'
            );
            const header$ = this._translateService.get(
              'COMMON.LABEL.HEADERS.LEAVE_PAGE'
            );
            forkJoin(unsaved$, yes$, no$, header$).subscribe((messages) => {
              this._confirmationService.confirm({
                header: messages[3],
                icon: 'fa fa-question-circle',
                acceptVisible: true,
                rejectVisible: true,
                acceptLabel: messages[1],
                rejectLabel: messages[2],
                message: messages[0],
                accept: () => {
                  if (!isNullOrUndefined(closingTab)) {
                    TabService.getInstance().closeTab(closingTab.index);
                  }
                },
                reject: () => {
                  TabService.getInstance().setActiveTab(closingTab.index);
                  TabService.getInstance().revertTabChangeEvent.emit();
                },
              });
            });
          }
        }
      }
    );
  }

  contactSearch($event) {
    if ($event.query) {
      this.filteredContacts = this.allContacts
        .filter((c) =>
          (c.firstName + ' ' + c.lastName)
            .toLowerCase()
            .startsWith($event.query.toLowerCase())
        )
        .filter((c: Contact) => c.inactiveAdAccount === false)
        .map((c) => ({ label: c.firstName + ' ' + c.lastName, value: c.id }));
    } else {
      this.filteredContacts = this.allContacts.map((c) => ({
        label: c.firstName + ' ' + c.lastName,
        value: c.id,
      }));
    }
  }
}

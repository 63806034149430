import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { take } from 'rxjs/operators';
import { REGION_DETAIL_CONTAINER } from 'src/app/constants/common.constants';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { TabService } from 'src/app/core/services/tab.service';
import { Region } from 'src/app/model/locations/region';
import { RegionApiService } from 'src/app/services/region-api.service';
import { RegionTableService } from 'src/app/services/region-table.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { REGION_TABLE_DEFINITION } from '../../definitions/region-table-definition';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';

@Component({
  selector: 'app-region-container',
  templateUrl: './region-container.component.html',
  styleUrls: ['./region-container.component.scss'],
})
export class RegionContainerComponent
  extends SearchContainerComponent<Region>
  implements OnInit
{
  allRegions: any[] = [];
  loading = false;
  canCreate = false;
  canEdit = false;
  mobileFilterCollapsed = true;

  constructor(
    private _translateService: TranslateService,
    private _breadCrumbBuilderService: BreadCrumbBuilderService,
    protected _deviceService: DeviceService,
    private _fb: UntypedFormBuilder,
    private _regionApi: RegionApiService,
    private _regionTableService: RegionTableService,
    protected _cdRef: ChangeDetectorRef,
    protected _loader: LoadingService,
    protected _auth: AuthService,
    private _authApi: AuthApiService,
    private _logAndMessage: LogAndMessageService
  ) {
    super(_deviceService, _loader, _auth, _cdRef);
  }

  ngOnInit(): void {
    this.setTableService(this._regionTableService);

    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }
  }

  initialize() {
    this._translateService.get('LOCATION.SCREEN.REGION').subscribe((label) => {
      if (this._deviceService.isMobile()) {
        this._breadCrumbBuilderService.resetAndAddBreadCrumb({
          label: label,
          routerLink: null,
        });
      } else {
        TabService.getInstance().updateActiveTabLabel(label);
      }
      this.screenName = label;
    });

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      'CreateRegions',
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList(['EditRegions']);

    this.columns = REGION_TABLE_DEFINITION;
    this.applyPreviousData();
    this.applyPreviousFilters();
  }

  clearScreen() {
    this.lazyLoad({});
    this.grid.resetTable();
    this._regionTableService.clearResults();
    this.elements = [];
    this.allRegions = [];
  }

  queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      sort[$event.lazy.sortField] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
      this._regionTableService.setLastSortField($event.lazy.sortField);
      this._regionTableService.setLastSortDirection(1);
    } else {
      sort['name'] = 'ASC';
      this._regionTableService.setLastSortField('name');
      this._regionTableService.setLastSortDirection(1);
    }
    this._regionApi
      .queryForRegions(req.pageSize, req.page, query, sort)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          const clone = Object.assign({}, data);
          const regions = clone.queryForRegions.items;
          this.elements = [...regions];
          this.totalRecords = clone.queryForRegions.totalRecords;
          this._regionTableService.setLastTotalRecords(this.totalRecords);
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this._logAndMessage.translateToErrorMessage({
            headerKey: 'LOCATION.MESSAGES.HEADERS.QUERY_REGION',
            bodyKey: 'LOCATION.MESSAGES.ERROR.QUERY_REGION_ERROR',
          });
        }
      );
  }

  handleLazyLoad(req, $event, filters) {
    const query = {
      name: null,
      contactList: null,
      owner: null,
      areas: null,
    };

    if (filters.name) {
      query.name = filters.name.value;
    }
    if (filters.contactList) {
      query.contactList = filters.contactList.value;
    }
    if (filters.owner) {
      query.owner = filters.owner.value;
    }
    if (filters.areas) {
      query.areas = filters.areas.value;
    }

    this.queryNetwork(req, $event, query);
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._regionTableService.getLastLazyLoad() });
  }

  newRegion() {
    const tab = TabService.getInstance().buildNewTab(
      REGION_DETAIL_CONTAINER,
      true
    );
    TabService.getInstance().openTab(tab);
  }

  changeViewEvent($event) {
    const tab = TabService.getInstance().buildNewTab(
      REGION_DETAIL_CONTAINER,
      true,
      null,
      $event.data.id
    );
    if (this._deviceService.isMobile()) {
      TabService.getInstance().setMobileTab(tab);
    } else {
      TabService.getInstance().openTab(tab);
    }
  }

  mobileFilter($event) {
    const newLazy: LazyLoadEvent = {
      filters: {},
    };
    this.mobileFilterCollapsed = true;
    this._regionTableService.setLastLazyLoad(newLazy);
    this.lazyLoad({ lazy: this._regionTableService.getLastLazyLoad() });
  }
}

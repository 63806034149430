import gql from "graphql-tag";

export const deleteArea = gql`
mutation deleteArea($id: String!) {
  deleteArea(id: $id) {
    id
    name
    contactListId
    owner {
      id
      firstName
      lastName
      nickName
    }
    region {
      id
      name
    }
    facilities {
      id
      facilityName
    }
    updater
    deletedAt
    version
  }
}
`;
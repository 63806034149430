import gql from "graphql-tag";

export const deleteRegion = gql`
mutation deleteRegion($id: String!) {
  deleteRegion(id: $id) {
    id
    name
    contactListId
    owner {
      id
      firstName
      lastName
      nickName
    }
    areas {
      id
      name
    }
    updater
    deletedAt
    version
  }
}
`;
import gql from "graphql-tag";

export const updateRegion = gql`
mutation updateRegion($id: String, $input: UpdateRegionInput) {
  updateRegion(id: $id, input: $input) {
    id
    name
    contactListId
    owner {
      id
      firstName
      lastName
      nickName
    }
    areas {
      id
      name
    }
    updater
    deletedAt
    version
  }
}`
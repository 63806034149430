<div class="notepad-container">
  <section class="header">
    <label for="enabledCategories"
      >{{ "LOCATION.LABEL.ENABLED_CATEGORIES" | translate }}:</label
    >
    <p-multiSelect
      [options]="categories"
      [(ngModel)]="selectedCategories"
      name="enabledCategories"
      (onChange)="filterEntries()"
    ></p-multiSelect>

    @if(canAddEntries) {
    <p-button
      label="Add Entry"
      class="p-button-raised p-button-success right-align"
      (click)="showEntryForm()"
      [disabled]="!canAddEntries"
    ></p-button>
    }
  </section>

  <p-divider></p-divider>

  <section class="notepad-entries">
    @for(entry of this.filteredEntries; track entry.id) {
    <app-facility-notepad-entry
      [entry]="entry"
      (entryDeletedEvent)="onEntryDelete(entry)"
      (editEntryEvent)="onEntryEdit(entry)"
    ></app-facility-notepad-entry>
    } @if (this.filteredEntries.length === 0) {
    <p>
      {{ "LOCATION.MESSAGES.INFO.NO_NOTEPAD_ENTRIES" | translate }}
    </p>
    }
  </section>
</div>

<p-dialog
  [header]="entryFormHeader"
  [(visible)]="showEntryDialog"
  showEffect="fade"
  [modal]="true"
  [transitionOptions]="'0ms'"
  (onHide)="onEntryDialogHide()"
  [style]="{ width: '1000px' }"
>
  @if (!entryFormWorking) {
  <form [formGroup]="addEntryForm" class="form">
    <div id="add-entry-create-form">
      <div class="panel">
        <div class="form-body">
          <div>
            <span class="label">Archived: </span>
            <span class="radio-wrapper">
              <p-radioButton
                name="archived"
                [value]="true"
                label="{{ 'COMMON.LABEL.BUTTONS.YES' | translate }}"
                formControlName="archived"
                styleClass="radio"
              ></p-radioButton>
            </span>
            <span class="radio-wrapper">
              <p-radioButton
                name="archived"
                [value]="false"
                label="{{ 'COMMON.LABEL.BUTTONS.NO' | translate }}"
                formControlName="archived"
                styleClass="radio"
              ></p-radioButton>
            </span>
          </div>
          <div>
            <span class="label">Category: </span>
            <span class="category-wrapper">
              <p-dropdown
                formControlName="category"
                [options]="categories"
                placeholder="Select Category"
              ></p-dropdown>
            </span>
          </div>
          <span class="label"
            >{{ "MONLOG.LABEL.PLEASE_INSERT_STATUS" | translate }}:</span
          >

          @if(showEntryDialog) {
          <p-editor
            formControlName="entryText"
            [style]="{ height: '300px' }"
            (onTextChange)="validateForm()"
          >
          </p-editor>
          }
        </div>

        <div class="label">
          {{ "MONLOG.LABEL.ENTRY_LABEL" | translate }}
        </div>

        <div class="modal-buttons">
          <button
            pButton
            label="{{ 'COMMON.LABEL.BUTTONS.OK' | translate }}"
            icon="fa fa-fw fa-check"
            (click)="saveEntry()"
            [disabled]="!this.addEntryForm.valid"
          ></button>
          <button
            pButton
            label="{{ 'COMMON.LABEL.BUTTONS.CANCEL' | translate }}"
            icon="fa fa-fw fa-ban"
            (click)="onEntryDialogHide()"
          ></button>
        </div>
      </div>
    </div>
  </form>
  } @else {
  <app-progress-spinner [enabled]="entryFormWorking"></app-progress-spinner>
  }
</p-dialog>

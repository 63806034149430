<div class="ui-g-12 ui-fluid mobile-filter">
  <div class="ui-g-12">
    <label class="mobile-filter-label" [translate]="'LOCATION.LABEL.REGION_NAME'"></label>
  </div>
  <div class="ui-g-12">
    <input pInputText type="text" [(ngModel)]="name" />
  </div>

  <div class="ui-g-12">
    <label class="mobile-filter-label" [translate]="'LOCATION.LABEL.OWNER'"></label>
  </div>
  <div class="ui-g-12">
    <input pInputText type="text" [(ngModel)]="owner" />
  </div>

  <div class="ui-g-12">
    <label class="mobile-filter-label" [translate]="'LOCATION.LABEL.CONTACT_LIST'"></label>
  </div>
  <div class="ui-g-12">
    <input pInputText type="text" [(ngModel)]="contactList" />
  </div>

  <div class="ui-g-12">
    <label class="mobile-filter-label" [translate]="'LOCATION.LABEL.AREAS'"></label>
  </div>
  <div class="ui-g-12">
    <input pInputText type="text" [(ngModel)]="areas" />
  </div>

  <div class="ui-g-12">
    <button type="button"
            pButton
            icon="fa fa-search"
            iconPos="left"
            label="{{'COMMON.LABEL.BUTTONS.RETRIEVE' | translate}}"
            class="nav-button filter-panel-button"
            (click)="mobileFilter()"></button>
  </div>
  <div class="ui-g-12">
    <button type="button"
            pButton
            icon="fa fa-fw fa-eraser"
            iconPos="left"
            label="{{'COMMON.LABEL.BUTTONS.CLEAR' | translate}}"
            class="nav-button filter-panel-button"
            (click)="clear()"></button>
  </div>
</div>
import gql from "graphql-tag";

export const getAvailableAreas = gql`
query getAvailableAreas {
  getAvailableAreas {
    id
    name
    region {
      id
      name
    }
  }
}`;
<div id="region-details">
  <app-progress-spinner [enabled]="!region"></app-progress-spinner>
  <p-blockUI [blocked]="saving === true || loading === true"></p-blockUI>
  <div *ngIf="region">
    <form *ngIf="form" [formGroup]="form" id="region-create-form">
      <div id="region-action-buttons-form">
        <div class="button-header">
          <div>
            <app-progress-spinner [enabled]="saving"></app-progress-spinner>
          </div>
          <div
            class="input"
            *ngIf="
              region?.id &&
              actions.length > 0 &&
              (canEdit || canDelete || canCreate)
            "
          >
            <span class="label">{{ "COMMON.LABEL.ACTIONS" | translate }}:</span>
            <span class="align-left">
              <p-dropdown
                [options]="actions"
                (onChange)="actionEvent($event, dd)"
                [(ngModel)]="selectedAction"
                [ngModelOptions]="{ standalone: true }"
                styleClass="action-drop-down"
                #dd
              ></p-dropdown>
            </span>
          </div>
          <button
            pButton
            *ngIf="
              (canEdit || canCreate) &&
              isEditing === true &&
              !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])
            "
            label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
            icon="fa fa-fw fa-save"
            (click)="saveRegion()"
            [disabled]="!this.form.valid || saving"
          ></button>
          <button
            pButton
            *ngIf="
              canEdit &&
              isEditing === false &&
              !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])
            "
            label="{{ 'COMMON.LABEL.BUTTONS.EDIT' | translate }}"
            icon="fa fa-fw fa-save"
            (click)="setEditing(true)"
          ></button>
        </div>
        <hr id="region-scroll-divider" />
        <p-scrollPanel styleClass="region-scroll-panel">
          <div>
            <div class="row">
              <div class="col row-item">
                <span class="label"
                  >{{ "LOCATION.LABEL.REGION_NAME" | translate }}:</span
                >
                <span class="row-input">
                  <input
                    type="text"
                    pInputText
                    class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input"
                    formControlName="name"
                  />
                </span>
              </div>
              <div class="col row-item">
                <span class="label"
                  >{{ "COMMON.LABEL.OWNER" | translate }}:</span
                >
                <span class="row-input">
                  <p-autoComplete
                    [suggestions]="filteredContacts"
                    (completeMethod)="contactSearch($event)"
                    formControlName="owner"
                    [dropdown]="true"
                    field="label"
                    appendTo="body"
                  ></p-autoComplete>
                </span>
              </div>
              <div class="col row-item">
                <span class="label"
                  >{{ "COMMON.LABEL.UPDATER" | translate }}:</span
                >
                <span class="row-input">
                  <input
                    type="text"
                    pInputText
                    class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input"
                    formControlName="updater"
                  />
                </span>
              </div>
            </div>
          </div>
          <div id="region-tab-view">
            <p-tabView [activeIndex]="tabIndex" (onChange)="tabChanged($event)">
              <p-tabPanel header="CONTACT LIST" class="tab-panel">
                <div *ngIf="contactList">
                  <app-contact-list-simple-view-container
                    [contactList]="contactList"
                    [embedded]="true"
                  >
                  </app-contact-list-simple-view-container>
                </div>
                <div *ngIf="!contactList && (canEdit || canCreate)">
                  <div *ngIf="!tempContactList">
                    <div>
                      Select a contact list from below{{
                        canCreateContactList ? " or create a new list." : "."
                      }}
                    </div>
                    <div>
                      <button
                        pButton
                        label="{{ 'COMMON.LABEL.BUTTONS.CREATE' | translate }}"
                        icon="fa fa-fw fa-plus"
                        *ngIf="canCreateContactList"
                        (click)="openCreateContactList()"
                        [disabled]="saving"
                      ></button>
                    </div>
                    <div>
                      <app-contact-list-search-container
                        [embedded]="true"
                        (selectedListEvent)="setTempList($event.data)"
                      >
                      </app-contact-list-search-container>
                    </div>
                  </div>
                  <div *ngIf="tempContactList">
                    List Associated will be saved when region is saved.
                  </div>
                </div>
                <div *ngIf="!contactList && !canEdit && !canCreate">
                  No contact list is associated with this Region.
                </div>
              </p-tabPanel>
              <p-tabPanel header="AREAS" class="tab-panel" *ngIf="region?.id">
                <div *ngIf="region?.areas?.length">
                  <div class="area-box" *ngFor="let area of region.areas">
                    <p-panel [showHeader]="false" class="area-card">
                      <div class="area-card">
                        <div class="area-rows">
                          <div class="section">{{ area.name }}</div>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                </div>
                <div *ngIf="!region?.areas?.length">
                  No areas associated with this Region.
                </div>
              </p-tabPanel>
            </p-tabView>
          </div>
        </p-scrollPanel>
      </div>
    </form>
  </div>

  <p-dialog
    header="Create Contact List"
    [(visible)]="createContactListDialog"
    showEffect="fade"
    [modal]="true"
    [transitionOptions]="'0ms'"
    [contentStyle]="{ 'max-height': '75vh' }"
  >
    <app-contact-list-create-container
      [embedded]="true"
      (newContactCreatedEvent)="setTempList($event)"
    >
    </app-contact-list-create-container>
  </p-dialog>
</div>

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { PickList, PickListModule } from 'primeng/picklist';
import { CoreModule } from 'src/app/core/core.module';
import { Facility } from 'src/app/model/locations/facility';
import { dynamicSort } from 'src/app/utils/utils';

@Component({
  selector: 'app-locations-form',
  standalone: true,
  imports: [
    DialogModule,
    CommonModule,
    ReactiveFormsModule,
    PickListModule,
    TranslateModule,
    ButtonModule,
    CoreModule,
  ],
  templateUrl: './locations-form.component.html',
  styleUrl: './locations-form.component.scss',
})
export class LocationsFormComponent {
  @ViewChild('pickList', { static: false }) pickList!: PickList;

  @Input() isVisible: boolean = false;
  @Input() states: any[] = [];
  @Input() counties: any[] = [];
  @Input() townships: any[] = [];
  @Input() formTitle: string = '';
  @Input() locationType: string = '';
  @Input() facility: Facility = undefined;

  @Output() locationsHidden: EventEmitter<any> = new EventEmitter();
  @Output() locationsUpdated: EventEmitter<any> = new EventEmitter();

  loading: boolean = false;
  saving: boolean = false;
  sourcePicklist: any[] = [];
  targetPicklist: any[] = [];

  availableItems: any[] = [];

  selectedStates: any[] = [];
  selectedCounties: any[] = [];
  selectedTownships: any[] = [];

  availableStates: any[] = [];
  availableCounties: any[] = [];
  availableTownships: any[] = [];

  manageLocationsForm = new UntypedFormGroup({
    location: new UntypedFormControl(null),
  });

  constructor() {}

  ngOnInit(): void {
    this.loadLocations(true);
  }

  loadLocations(firstLoad: boolean) {
    this.sourcePicklist = [];
    this.availableItems = [];

    if (firstLoad && this.facility && this.facility.locations?.length > 0)
    {
      this.selectedStates = Array.from(new Set(this.facility.locations.map(loc => ({label: loc.state}))));
      this.selectedStates = this.selectedStates.filter((a, b) => this.selectedStates.findIndex(state => state["label"] === a["label"]) === b);

      this.selectedCounties = Array.from(new Map(this.facility.locations.map(loc => ([loc.county, { state: loc.state, county: loc.county, __typename: 'County' }]))).values());
      this.selectedTownships = this.facility.locations.map((loc => ({ id: loc.id, state: loc.state, county: loc.county, township: loc.township, __typename: 'Township' })));
    }

    if (this.locationType === 'State')
    {
      this.availableStates = this.getAvailableStates();
      this.availableItems = this.availableStates.filter(state => !this.selectedStates.some(s => s.label == state.label)).sort(dynamicSort('label', 1));

      this.sourcePicklist.push(...this.availableItems);

      this.targetPicklist = this.selectedStates;
    }
    else if (this.locationType == 'County')
    {
      this.availableCounties = this.getAvailableCounties();
      this.availableItems = this.availableCounties.filter(loc => !this.selectedCounties.some(sc => sc.county === loc.county)).sort(dynamicSort('county', 1));

      this.sourcePicklist.push(...this.availableItems);

      this.targetPicklist = this.selectedCounties;
    }
    else {
      this.availableTownships = this.getAvailableTownships();
      this.availableItems = this.availableTownships.filter(loc => !this.selectedTownships.some(st => st.township === loc.township && st.county === loc.county)).sort(dynamicSort('township', 1));

      this.sourcePicklist.push(...this.availableItems);

      this.targetPicklist = this.selectedTownships;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['locationType'])
    {
      this.locationType = changes['locationType'].currentValue;
    }

    this.loadLocations(false);
  }

  updateLocations() {
    if (this.locationType === 'State')
    {
      this.selectedStates = [...this.targetPicklist];

      this.selectedCounties = this.selectedCounties.filter(sc => this.selectedStates.some(state => state.label === sc.state));
      this.selectedTownships = this.selectedTownships.filter(st => this.selectedCounties.some(sc => sc.county === st.county));
    }
    else if (this.locationType == 'County')
    {
      this.selectedCounties = [...this.targetPicklist];

      this.selectedTownships = this.selectedTownships.filter(st => this.selectedCounties.some(sc => sc.county === st.county));
    }
    else
    {
      this.selectedTownships = [...this.targetPicklist];
    }

    this.locationsUpdated.emit([...this.targetPicklist]);

    this.targetPicklist = [];
    this.pickList?.resetFilter();
  }

  onDialogHide() {
    this.locationsHidden.emit();
  }

  getAvailableStates() {
    if (this.selectedStates?.length === 0 && this.selectedCounties?.length > 0)
    {
      return this.states.filter(state => this.selectedTownships.some(sc => sc.state === state)).map(s => ({label: s}));
    }

    return this.states.map(s => ({label: s}));
  }

  getAvailableCounties() {
    if (this.selectedStates?.length > 0)
    {
      return this.counties.filter(c => this.selectedStates.some(fs => fs.label === c.state));
    }

    if (this.selectedCounties?.length === 0 && this.selectedTownships?.length > 0)
    {
      return this.counties.filter(loc => this.selectedTownships.some(st => st.county === loc.county));
    }

    return this.counties;
  }

  getAvailableTownships() {
    if (this.selectedStates?.length > 0 && this.selectedCounties?.length > 0)
    {
      return this.townships.filter(t => this.selectedStates.some(state => state.label === t.state) && this.selectedCounties.some(county => county.county === t.county));
    }
    else if (this.selectedCounties?.length > 0)
    {
      return this.townships.filter(t => this.selectedCounties.some(county => county === t.county));
    }
    else if (this.selectedStates?.length > 0)
    {
      return this.townships.filter(t => this.selectedStates.some(state => state.label === t.state));
    }

    return this.townships;
  }

  getPluralLabel()
  {
    if (this.locationType === 'State')
    {
      return 'States';
    }
    else if (this.locationType === 'County')
    {
      return 'Counties';
    }
    else
    {
      return 'Townships';
    }
  }
}

@if (contactList) {
<h1>
  On-Call
  <i
    class="fa fa-fw fa-address-book fa-2x"
    (click)="openContactList(contactList)"
  ></i>
</h1>

<section class="on-call-container">
  <app-contact-list-card
    [member]="contactList?.members[0]"
    [index]="0"
    (openProfileEvent)="openProfile($event)"
  ></app-contact-list-card>
  <app-contact-list-card
    [member]="contactList?.members[1]"
    [index]="1"
    (openProfileEvent)="openProfile($event)"
  ></app-contact-list-card>
</section>
} @if (managementContactList) {
<br />
<h2>
  Management On-Call
  <i
    class="fa fa-fw fa-address-book fa-2x"
    (click)="openContactList(managementContactList)"
  ></i>
</h2>

<app-contact-list-card
  [member]="managementContactList?.members[0]"
  [index]="0"
  (openProfileEvent)="openProfile($event)"
></app-contact-list-card>
} @if (!contactList && !managementContactList) { No contact lists to show }

import { Injectable } from '@angular/core';
import { BaseCacheService } from './base-cache.service';
import { Location } from '../model/locations/location';
import { BaseTableService } from './base-table.service';

@Injectable({
  providedIn: 'root'
})
export class LocationTableService extends BaseTableService<Location> {
  constructor() {
    super();
  }
}

import gql from "graphql-tag";

export const createRegion = gql`
mutation createRegion($input: CreateRegionInput) {
  createRegion(input: $input) {
    id
    name
    contactListId
    owner {
      id
      firstName
      lastName
      nickName
    }
    areas {
      id
      name
    }
    updater
    deletedAt
    version
  }
}`
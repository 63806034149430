import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CoreModule } from 'src/app/core/core.module';
import { Facility } from 'src/app/model/locations/facility';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { FacilityNotepadEntryComponent } from '../facility-notepad-entry/facility-notepad-entry.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { SelectItem } from 'primeng/api';
import { FacilityNotepadEntry } from 'src/app/model/locations/facility-notepad-entry';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { take } from 'rxjs/operators';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { DividerModule } from 'primeng/divider';

export enum ArchivedEntryStatus {
  ArchivedOnly,
  NonArchivedOnly,
  Both,
}
@Component({
  selector: 'app-facility-notepad',
  standalone: true,
  imports: [
    ButtonModule,
    CoreModule,
    FacilityNotepadEntryComponent,
    TranslateModule,
    DropdownModule,
    DividerModule,
  ],
  templateUrl: './facility-notepad.component.html',
  styleUrl: './facility-notepad.component.scss',
})
export class FacilityNotepadComponent implements OnInit, OnChanges {
  @Input() facility: Facility;
  @Input() archivedStatus: ArchivedEntryStatus;

  @Output() notepadChangedEvent: EventEmitter<any> = new EventEmitter();

  currentlyEditingEntry: FacilityNotepadEntry = null;
  addEntryForm: FormGroup;
  categories: SelectItem[] = [];
  selectedCategories: string[] = [];
  showEntryDialog: boolean = false;
  canAddEntries: boolean = false;
  entryFormWorking: boolean = false;
  entryFormHeader: string = 'Add Entry to Facility Notepad';
  filteredEntries: FacilityNotepadEntry[];

  constructor(
    private facilityApi: FacilityApiService,
    private fb: FormBuilder,
    private authApi: AuthApiService,
    private auth: AuthService,
    private contactApi: ContactApiService
  ) {
    this.addEntryForm = this.fb.group({
      archived: false,
      category: [null, Validators.required],
      entryText: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.facilityApi.getFacilityNotepadCategories().subscribe((categories) => {
      this.categories = categories.map((cat) => ({
        label: cat.name,
        value: cat.id,
      }));

      this.selectedCategories = this.categories.map((cat) => cat.value);
      this.filterEntries();
    });

    this.checkPermissions();
    this.filterEntries();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update entries arrays when input changes
    this.filterEntries();
  }

  filterEntries(): void {
    switch (this.archivedStatus) {
      case ArchivedEntryStatus.Both:
        this.filteredEntries = this.facility.NotepadEntries;
        break;
      case ArchivedEntryStatus.ArchivedOnly:
        this.filteredEntries = this.facility.NotepadEntries?.filter(
          (entry) => entry.archived
        );
        break;
      case ArchivedEntryStatus.NonArchivedOnly:
        this.filteredEntries = this.facility.NotepadEntries?.filter(
          (entry) => !entry.archived
        );
        break;
      default:
        this.filteredEntries = [];
        console.error('Got invalid archived status!', this.archivedStatus);
    }

    this.filteredEntries = this.filteredEntries.filter((entry) =>
      this.selectedCategories.some((id) => id === entry.Category.id)
    );
  }

  showEntryForm(): void {
    this.entryFormHeader = 'Add Entry to Facility Notepad';
    this.showEntryDialog = true;
    if (!this.currentlyEditingEntry) {
      this.addEntryForm.get('archived').disable();
    } else {
      this.entryFormHeader = 'Edit Facility Notepad Entry';
      this.checkAndSetArchivePermissions(this.currentlyEditingEntry);
    }
  }

  onEntryDialogHide(): void {
    this.showEntryDialog = false;
    this.addEntryForm.patchValue({
      archived: false,
      category: null,
      entryText: null,
    });
    this.currentlyEditingEntry = null;
  }

  validateForm(): void {
    this.addEntryForm.updateValueAndValidity();
  }

  saveEntry(): void {
    this.entryFormWorking = true;
    const formValue = this.addEntryForm.getRawValue();
    if (this.currentlyEditingEntry) {
      // Edit logic
      this.facilityApi
        .editFacilityNotepadEntry(this.currentlyEditingEntry.id, {
          categoryId: formValue.category,
          archived: formValue.archived,
          note: formValue.entryText,
        })
        .subscribe(
          (result) => {
            this.notepadChangedEvent.emit();
            this.onEntryDialogHide();
            this.entryFormWorking = false;
          },
          (error) => {
            console.error(error);
            this.entryFormWorking = false;
          }
        );
    } else {
      // Create logic
      this.facilityApi
        .createFacilityNotepadEntry({
          facilityId: this.facility.id,
          categoryId: formValue.category,
          note: formValue.entryText,
        })
        .subscribe(
          (result) => {
            this.notepadChangedEvent.emit();
            this.onEntryDialogHide();
            this.entryFormWorking = false;
          },
          (error) => {
            console.error(error);
            this.entryFormWorking = false;
          }
        );
    }

    this.currentlyEditingEntry = null;
  }

  onEntryDelete(entry: FacilityNotepadEntry): void {
    this.notepadChangedEvent.emit();
  }

  onEntryEdit(entry: FacilityNotepadEntry): void {
    this.currentlyEditingEntry = entry;
    this.addEntryForm.patchValue({
      archived: entry.archived,
      category: entry.categoryId,
      entryText: entry.note,
    });
    this.showEntryForm();
  }

  checkPermissions(): void {
    if (this.archivedStatus === ArchivedEntryStatus.ArchivedOnly) {
      this.canAddEntries = false;
      return;
    }

    if (this.authApi.doesUserHaveClaim('AddAllFacilityNotepadEntries')) {
      this.canAddEntries = true;
      return;
    }

    if (
      this.authApi.doesUserHaveClaim('AddDirectReportsFacilityNotepadEntries')
    ) {
      this.checkUserFacilitiesAndEnableAdd(true);
      return;
    }

    if (this.authApi.doesUserHaveClaim('AddOwnFacilityNotepadEntries')) {
      this.checkUserFacilitiesAndEnableAdd(false);
      return;
    }
  }

  checkUserFacilitiesAndEnableAdd(includeDirectReports: boolean): void {
    this.facilityApi
      .getUserFacilities(this.auth.getEmail(), includeDirectReports)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          let userFacilities: {
            id: string;
            facilityName: string;
            facilityUid: string;
          }[] = data.getUserFacilities;
          if (
            userFacilities.some((facility) => facility.id === this.facility.id)
          ) {
            this.canAddEntries = true;
          }
        },
        (error) => console.error(error)
      );
    return;
  }

  checkAndSetArchivePermissions(entry: FacilityNotepadEntry): void {
    if (this.authApi.doesUserHaveClaim('ArchiveAllFacilityNotepadEntries')) {
      this.addEntryForm.get('archived').enable();
      return;
    }

    if (
      this.authApi.doesUserHaveClaim(
        'ArchiveDirectReportsFacilityNotepadEntries'
      )
    ) {
      this.contactApi
        .queryForWhoReportsToMe(this.auth.getEmail())
        .pipe(take(1))
        .subscribe(({ data }) => {
          let usernames: string[] = data.queryWhoReportsToMe.map((item) =>
            `${item.firstName} ${item.lastName}`.toLowerCase()
          );
          if (
            usernames.some((name) =>
              entry.createdBy.toLowerCase().startsWith(name)
            )
          ) {
            this.addEntryForm.get('archived').enable();
          } else {
            this.addEntryForm.get('archived').disable();
          }
        });
    }

    if (this.authApi.doesUserHaveClaim('ArchiveOwnFacilityNotepadEntries')) {
      if (
        entry.createdBy === this.auth.getUserName() ||
        this.auth.getEmail().startsWith(entry.createdBy)
      ) {
        this.addEntryForm.get('archived').enable();
      }
      return;
    }

    this.addEntryForm.get('archived').disable();
  }
}

import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { take } from 'rxjs/operators';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { TabService } from 'src/app/core/services/tab.service';
import { Location } from 'src/app/model/locations/location';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocationTableService } from 'src/app/services/location-table.service';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { v4 as uuid } from 'uuid';
import { LocationApiService } from '../../../services/location-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { LOCATION_TABLE_DEFINITION } from '../../definitions/location-table-definition';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { dynamicSort } from 'src/app/utils/utils';
import { BusinessUnit } from 'src/app/model/admin/business-unit';
import { isNullOrUndefined } from 'util';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';

@Component({
  selector: 'app-location-container',
  templateUrl: './location-container.component.html',
  styleUrls: ['./location-container.component.scss'],
})
export class LocationContainerComponent
  extends SearchContainerComponent<Location>
  implements OnInit, OnDestroy
{
  @ViewChild('buFilter') businessUnitFilter: BusinessUnitFilterComponent;
  allLocations: any[] = [];
  loading = false;
  locationCreateForm: UntypedFormGroup;
  displayDialog = false;
  filteredStates: any[];
  filteredCounties: any[];
  filteredTownships: any[];
  itemsPerPage = 1948;
  finishedFetching = false;
  subscription: any;
  tick: any;
  shouldLoadMore = 10;
  first = false;
  count = 0;
  availableStates: any[];
  availableCounties: any[];
  availableTownships: any[];
  canCreate = false;
  canEdit = false;
  saving = false;
  isEditting = false;
  selectedLocation: Location;
  mobileFilterCollapsed = true;

  availableBusinessUnits: BusinessUnit[] = [];
  sourcePicklist: BusinessUnit[] = [];
  targetPicklist: BusinessUnit[] = [];

  constructor(
    private _translateService: TranslateService,
    private _breadCrumbBuilderService: BreadCrumbBuilderService,
    protected _deviceService: DeviceService,
    private _fb: UntypedFormBuilder,
    private _locationApi: LocationApiService,
    private _locationTableService: LocationTableService,
    private _authApi: AuthApiService,
    private _adminApi: AdminApiService,
    protected _cdRef: ChangeDetectorRef,
    protected _loader: LoadingService,
    protected _auth: AuthService
  ) {
    super(_deviceService, _loader, _auth, _cdRef);
  }

  ngOnInit() {
    this.setTableService(this._locationTableService);
    this.locationCreateForm = this._fb.group({
      state: [null, Validators.required],
      county: [null, Validators.required],
      township: [null, Validators.required],
      id: [null],
      businessUnits: [{ value: null, disabled: false }],
    });

    if (!this.isEditting) {
      this.locationCreateForm.disable();
    }

    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }
  }

  initialize() {
    this._translateService
      .get('LOCATION.SCREEN.LOCATION')
      .subscribe((label) => {
        if (this._deviceService.isMobile()) {
          this._breadCrumbBuilderService.resetAndAddBreadCrumb({
            label: label,
            routerLink: null,
          });
        } else {
          TabService.getInstance().updateActiveTabLabel(label);
        }
        this.screenName = label;
      });

    this._adminApi
      .getAvailableBusinessUnits()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = { ...data };
        this.availableBusinessUnits = clone.getAvailableBusinessUnits;
      });

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      'CreateLocations',
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      'EditLocations',
    ]);

    this.columns = LOCATION_TABLE_DEFINITION;
    this.applyPreviousData();
    this.applyPreviousFilters();

    this._locationApi
      .getAvailableStates()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = { ...data };
        this.availableStates = clone.getAvailableStates.sort();
      });

    this._locationApi
      .getAvailableCounties()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = { ...data };
        this.availableCounties = clone.getAvailableCounties.sort(
          dynamicSort('county', 1)
        );
      });

    this._locationApi
      .getAvailableTownships()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = { ...data };
        this.availableTownships = clone.getAvailableTownships.sort(
          dynamicSort('township', 1)
        );
      });
  }

  /**
   * Method called when the user hits the clear button.
   */
  clearScreen() {
    this.lazyLoad({});
    this.grid.resetFilters();
    this.grid.resetTable();
    this._locationTableService.clearResults();
    this.elements = [];
    this.allLocations = [];
  }

  changeViewEvent(event) {
    this.selectedLocation = event.data;
    this.locationCreateForm.patchValue({
      state: event.data.state,
      county: { county: event.data.county },
      township: event.data.township,
      id: event.data.id,
      updater: event.data.id,
      businessUnits: event.data.businessUnits,
    });
    const businessUnits =
      this.locationCreateForm.controls['businessUnits'].value;
    let currentBusinessUnits = [];
    businessUnits.forEach((x) => {
      currentBusinessUnits.push(x.name);
    });
    this.sourcePicklist = [];
    this.targetPicklist = [];
    this.availableBusinessUnits.forEach((x) => {
      if (currentBusinessUnits.includes(x.name)) {
        this.targetPicklist.push(x);
      } else {
        this.sourcePicklist.push(x);
      }
    });

    this.displayDialog = true;
    this.locationCreateForm.disable();
  }

  newLocation() {
    this.locationCreateForm.reset();
    this.sourcePicklist = [];
    this.targetPicklist = [];
    this.availableBusinessUnits.forEach((x) => {
      this.sourcePicklist.push(x);
    });
    this.isEditting = true;
    this.displayDialog = true;
    if (this.canCreate) {
      this.locationCreateForm.enable();
    }
  }

  deleteLocation() {
    if (this.canEdit) {
      const id = this.locationCreateForm.controls['id'].value;
      if (!id) {
        return;
      }

      this._locationApi
        .deleteLocation(id)
        .pipe(take(1))
        .subscribe(
          (result) => {
            this.clearForm();
            this.displayDialog = false;
            this.lazyLoad({
              lazy: this._locationTableService.getLastLazyLoad(),
            });
          },
          (error) => {
            console.log('Error creating message', error);
          }
        );
    }
  }

  createLocation(location) {
    if (!this.canCreate) {
      return;
    }
    location.id = uuid();
    location.updater = null;
    location.updatedAt = null;
    location.createdAt = null;
    const assignedUnits: BusinessUnit[] = !isNullOrUndefined(
      this.targetPicklist
    )
      ? this.targetPicklist
      : [];
    const locationInput = Object.assign(
      {},
      {
        id: location.id,
        state: location.state,
        county: location.county,
        township: location.township,
        businessUnits: assignedUnits.map((m) => ({
          id: m.id,
          name: m.name,
        })),
      }
    );

    this.locationCreateForm.disable();

    this._locationApi
      .createLocation(locationInput)
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.clearForm();
          this.displayDialog = false;
          this.lazyLoad({ lazy: this._locationTableService.getLastLazyLoad() });
          this.isEditting = false;
          this.locationCreateForm.disable();
        },
        (error) => {
          console.log('Error creating message', error);
          this.isEditting = true;
          this.locationCreateForm.enable();
        }
      );
  }

  updateLocation(location) {
    if (!this.canEdit) {
      return;
    }
    const assignedUnits: BusinessUnit[] = !isNullOrUndefined(
      this.targetPicklist
    )
      ? this.targetPicklist
      : [];
    const locationInput = Object.assign(
      {},
      {
        id: location.id,
        state: location.state,
        county: location.county,
        township: location.township,
        businessUnits: assignedUnits.map((m) => ({
          id: m.id,
          name: m.name,
        })),
      }
    );

    this.locationCreateForm.disable();

    this._locationApi
      .updateLocation(locationInput.id, locationInput)
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.clearForm();
          this.displayDialog = false;
          this.lazyLoad({ lazy: this._locationTableService.getLastLazyLoad() });
          this.isEditting = false;
          this.locationCreateForm.disable();
        },
        (error) => {
          console.log('Error creating message', error);
          this.isEditting = true;
          this.locationCreateForm.enable();
        }
      );
  }

  saveLocation() {
    if (!this.canEdit && !this.canCreate) {
      return;
    }

    if (this.locationCreateForm.valid) {
      const location = Object.assign({}, this.locationCreateForm.getRawValue());
      // need to support objects and user input.
      if (location.county.hasOwnProperty('county')) {
        location.county = location.county.county;
      }
      if (location.township.hasOwnProperty('township')) {
        location.township = location.township.township;
      }
      if (!location.id) {
        this.createLocation(location);
      } else {
        this.updateLocation(location);
      }
    }
  }

  clearForm() {
    this.locationCreateForm.reset();
  }

  stateSearch($event) {
    if ($event.query === '') {
      this.filteredStates = [...this.availableStates];
    } else {
      this.filteredStates = [
        ...this.availableStates.filter((c) =>
          c.toLowerCase().startsWith($event.query.toLowerCase())
        ),
      ];
    }
  }

  countySearch($event) {
    const state = this.locationCreateForm.controls['state'].value;
    if ($event.query === '') {
      if (!state) {
        this.filteredCounties = [...this.availableCounties];
      } else {
        this.filteredCounties = [
          ...this.availableCounties.filter((c) => c.state === state),
        ];
      }
    } else {
      if (!state) {
        this.filteredCounties = [
          ...this.availableCounties.filter((c) =>
            c.county.toLowerCase().startsWith($event.query.toLowerCase())
          ),
        ];
      } else {
        this.filteredCounties = [
          ...this.availableCounties.filter(
            (c) =>
              c.county.toLowerCase().startsWith($event.query.toLowerCase()) &&
              c.state === state
          ),
        ];
      }
    }
  }

  selectCounty($event) {
    this.locationCreateForm.patchValue({
      state: $event.state,
    });
  }

  townshipSearch($event) {
    const state = this.locationCreateForm.controls['state'].value;
    const county = this.locationCreateForm.controls['county'].value;
    if (state) {
      if (county) {
        if ($event.query === '') {
          this.filteredTownships = [
            ...this.availableTownships.filter(
              (c) => c.state === state && c.county === county
            ),
          ];
        } else {
          this.filteredTownships = [
            ...this.availableTownships.filter(
              (c) =>
                c.state === state &&
                c.county === county &&
                c.township.toLowerCase().startsWith($event.query.toLowerCase())
            ),
          ];
        }
      } else {
        if ($event.query === '') {
          this.filteredTownships = [
            ...this.availableTownships.filter((c) => c.state === state),
          ];
        } else {
          this.filteredTownships = [
            ...this.availableTownships.filter(
              (c) =>
                c.state === state &&
                c.township.toLowerCase().startsWith($event.query.toLowerCase())
            ),
          ];
        }
      }
    } else {
      if ($event.query === '') {
        this.filteredTownships = [...this.availableTownships];
      } else {
        this.filteredTownships = [
          ...this.availableTownships.filter((c) =>
            c.township.toLowerCase().startsWith($event.query.toLowerCase())
          ),
        ];
      }
    }
  }

  selectTownship($event) {
    this.locationCreateForm.patchValue({
      locationId: $event.id,
      state: $event.state,
      county: { county: $event.county, state: $event.state },
    });
  }

  queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      sort[$event.lazy.sortField] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
      this._locationTableService.setLastSortField($event.lazy.sortField);
      this._locationTableService.setLastSortDirection($event.lazy.sortOrder);
    } else {
      sort['state'] = 'ASC';
      this._locationTableService.setLastSortField('state');
      this._locationTableService.setLastSortDirection(1);
    }
    this._locationApi
      .queryForLocations(req.pageSize, req.page, query, sort)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          const clone = Object.assign({}, data);
          for (let i = 0; i < clone.queryForLocations.items.length; ++i) {
            let businessUnitsString = '';
            for (
              let j = 0;
              j < clone.queryForLocations.items[i].businessUnits.length;
              ++j
            ) {
              businessUnitsString +=
                clone.queryForLocations.items[i].businessUnits[j].name;
              if (j + 1 < clone.queryForLocations.items[i].businessUnits.length)
                businessUnitsString += ', ';
            }
            clone.queryForLocations.items[i].businessUnitString =
              businessUnitsString;
          }
          const locs = clone.queryForLocations.items;
          this.totalRecords = clone.queryForLocations.totalRecords;
          this.elements = [...locs];
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  ngOnDestroy(): void {}

  handleLazyLoad(req, $event, filters) {
    let selectedBusinessUnits = [];
    if (this.businessUnitFilter?.selectedBusinessUnits) {
      // Handle further refreshes & changes to business unit filter component
      selectedBusinessUnits = this.businessUnitFilter.selectedBusinessUnits;
    } else {
      // Handle initial page load (before the business unit filter subcomponent exists)
      selectedBusinessUnits = this._authApi
        .getUserBusinessUnits()
        .map((bu) => bu.id);
    }
    filters['businessUnits'] = selectedBusinessUnits;

    const query = {
      state: null,
      county: null,
      township: null,
      businessUnits: null,
      businessUnitString: null,
    };

    if (filters.state) {
      query.state = filters.state.value;
    }
    if (filters.county) {
      query.county = filters.county.value;
    }
    if (filters.township) {
      query.township = filters.township.value;
    }
    if (filters.businessUnits) {
      query.businessUnits = filters.businessUnits;
    }
    if (filters.businessUnitString) {
      query.businessUnitString = filters.businessUnitString?.value;
    }

    this.queryNetwork(req, $event, query);
  }

  handleBusinessUnitChange($event) {
    this.lazyLoad({ lazy: this.tableService.getLastLazyLoad() });
  }

  editLocation() {
    this.isEditting = true;
    this.locationCreateForm.enable();
  }

  mobileFilter($event) {
    const newLazy: LazyLoadEvent = {
      filters: {
        ...this.grid.table.filters,
        state: $event.state
          ? {
              value: $event.state,
              matchMode: 'contains',
            }
          : null,
        county: $event.county
          ? {
              value: $event.county,
              matchMode: 'contains',
            }
          : null,
        township: $event.township
          ? {
              value: $event.township,
              matchMode: 'contains',
            }
          : null,
      },
    };
    this.mobileFilterCollapsed = true;
    this._locationTableService.setLastLazyLoad(newLazy);
    this.lazyLoad({ lazy: this._locationTableService.getLastLazyLoad() });
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._locationTableService.getLastLazyLoad() });
  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { take } from 'rxjs/operators';
import { CoreModule } from 'src/app/core/core.module';
import { FacilityNotepadEntry } from 'src/app/model/locations/facility-notepad-entry';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';

@Component({
  selector: 'app-facility-notepad-entry',
  standalone: true,
  imports: [CommonModule, ButtonModule, CoreModule],
  templateUrl: './facility-notepad-entry.component.html',
  styleUrl: './facility-notepad-entry.component.scss',
})
export class FacilityNotepadEntryComponent implements OnInit {
  @Input() entry: FacilityNotepadEntry;

  @Output() editEntryEvent: EventEmitter<FacilityNotepadEntry> =
    new EventEmitter();
  @Output() entryDeletedEvent: EventEmitter<FacilityNotepadEntry> =
    new EventEmitter();

  entryWorking: boolean = false;
  userReportsNames: string[] = [];

  constructor(
    private facilityApi: FacilityApiService,
    private confirmationService: ConfirmationService,
    private authApi: AuthApiService,
    private _auth: AuthService,
    private contactApi: ContactApiService
  ) {}

  ngOnInit(): void {
    this.loadUserReports();
  }

  editEntry(entry: FacilityNotepadEntry): void {
    this.editEntryEvent.emit(entry);
  }

  deleteEntry(entry: FacilityNotepadEntry): void {
    this.confirmationService.confirm({
      header: 'Delete Entry?',
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: 'OK',
      rejectLabel: 'Cancel',
      message: "Are you sure you'd like to delete this facility notepad entry?",
      accept: () => {
        this.entryWorking = true;
        this.facilityApi.deleteFacilityNotepadEntry(entry.id).subscribe(
          (result) => {
            this.entryWorking = false;
            this.entryDeletedEvent.emit(this.entry);
          },
          (error) => {
            console.error(error);
            this.entryWorking = false;
          }
        );
      },
      reject: () => {},
    });
  }

  canEditThisEntry(): boolean {
    if (
      this.authApi.doesUserHaveAtLeastOneClaimFromList([
        'EditAllFacilityNotepadEntries',
        'ArchiveAllFacilityNotepadEntries',
      ])
    ) {
      return true;
    }

    if (
      this.authApi.doesUserHaveAtLeastOneClaimFromList([
        'EditOwnFacilityNotepadEntries',
        'ArchiveOwnFacilityNotepadEntries',
      ]) &&
      this.isOwnEntry()
    ) {
      return true;
    }

    if (
      this.authApi.doesUserHaveAtLeastOneClaimFromList([
        'EditDirectReportsFacilityNotepadEntries',
        'ArchiveDirectReportsFacilityNotepadEntries',
      ])
    ) {
      return this.userReportsNames.some((name) =>
        this.entry.createdBy.toLowerCase().startsWith(name)
      );
    }
  }

  canDeleteThisEntry(): boolean {
    if (
      this.authApi.doesUserHaveAllClaimsFromList([
        'DeleteAllFacilityNotepadEntries',
      ])
    ) {
      return true;
    }

    if (
      this.authApi.doesUserHaveAllClaimsFromList([
        'DeleteOwnFacilityNotepadEntries',
      ]) &&
      this.isOwnEntry()
    ) {
      return true;
    }

    if (
      this.authApi.doesUserHaveAllClaimsFromList([
        'DeleteDirectReportsFacilityNotepadEntries',
      ])
    ) {
      return this.userReportsNames.some((name) =>
        this.entry.createdBy.toLowerCase().startsWith(name)
      );
    }
  }

  isOwnEntry(): boolean {
    return (
      this.entry.createdBy === this._auth.getUserName() ||
      this._auth.getEmail().startsWith(this.entry.createdBy)
    );
  }

  loadUserReports(): void {
    this.contactApi
      .queryForWhoReportsToMe(this._auth.getEmail())
      .pipe(take(1))
      .subscribe(({ data }) => {
        let usernames = data.queryWhoReportsToMe.map((item) =>
          `${item.firstName} ${item.lastName}`.toLowerCase()
        );
        this.userReportsNames = usernames;
      });
  }
}

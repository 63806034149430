import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-area-mobile-filter',
  templateUrl: './area-mobile-filter.component.html',
  styleUrls: ['./area-mobile-filter.component.scss']
})
export class AreaMobileFilterComponent implements OnInit {

  @Output() mobileFilterEvent = new EventEmitter<any>();

  name: string;
  region: string;
  owner: string;
  contactList: string;
  facilities: string;

  constructor() { }

  ngOnInit() {
  }

  mobileFilter() {
    this.mobileFilterEvent.emit({name: this.name, region: this.region, owner: this.owner, contactList: this.contactList, facilities: this.facilities });
  }

  clear() {
    this.name = null;
    this.region = null;
    this.owner = null;
    this.contactList = null;
    this.facilities = null;
    this.mobileFilter();
  }

}

import { Injectable } from "@angular/core";
import { Area } from "../model/locations/area";
import { BaseTableService } from "./base-table.service";

@Injectable({
    providedIn: 'root'
})
export class AreaTableService extends BaseTableService<Area> {
    constructor() {
        super();
    }
}
import { Injectable } from "@angular/core";
import { Region } from "../model/locations/region";
import { BaseTableService } from "./base-table.service";

@Injectable({
    providedIn: 'root'
})
export class RegionTableService extends BaseTableService<Region> {
    constructor() {
        super();
    }
}
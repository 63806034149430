import { Component, Input } from '@angular/core';
import {
  CONTACT_DETAIL_CONTAINER,
  CONTACT_LIST_DETAIL_CONTAINER,
} from 'src/app/constants/common.constants';
import { ContactsModule } from 'src/app/contacts/contacts.module';
import { TabService } from 'src/app/core/services/tab.service';
import { ContactList } from 'src/app/model/contacts/contact-list';
import { ContactTableService } from 'src/app/services/contact-table.service';

@Component({
  selector: 'app-facility-contact-list-embed',
  standalone: true,
  imports: [ContactsModule],
  templateUrl: './facility-contact-list-embed.component.html',
  styleUrl: './facility-contact-list-embed.component.scss',
})
export class FacilityContactListEmbedComponent {
  @Input() contactList: ContactList;
  @Input() managementContactList: ContactList;

  constructor(private _contactTable: ContactTableService) {}

  openContactList(contactList: ContactList) {
    const tab = TabService.getInstance().buildNewTab(
      CONTACT_LIST_DETAIL_CONTAINER,
      true,
      null,
      contactList.id
    );
    TabService.getInstance().openTab(tab);
  }

  openProfile(id: string) {
    // clear out the selected to make sure a previously viewed contact isn't used.
    this._contactTable.setSelected(undefined);
    this._contactTable.setScreenEmbedded(false);
    const tab = TabService.getInstance().buildNewTab(
      CONTACT_DETAIL_CONTAINER,
      true,
      null,
      id
    );
    TabService.getInstance().openTab(tab);
  }
}

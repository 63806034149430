import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocationsRoutingModule } from './locations-routing.module';
import { LocationContainerComponent } from './containers/location-container/location-container.component';
import { FacilityContainerComponent } from './containers/facility-container/facility-container.component';
import { CoreModule } from '../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { FacilityDetailsContainerComponent } from './containers/facility-container/facility-details-container.component';
import { ContactsModule } from '../contacts/contacts.module';
import { FacilityMobileFilterComponent } from './components/facility-mobile-filter/facility-mobile-filter.component';
import { LocationMobileFilterComponent } from './components/location-mobile-filter/location-mobile-filter.component';
import { PickListModule } from 'primeng/picklist';
import { AreaContainerComponent } from './containers/area-container/area-container.component';
import { AreaMobileFilterComponent } from './components/area-mobile-filter/area-mobile-filter.component';
import { AreaDetailsContainerComponent } from './containers/area-container/area-details-container.component';
import { RegionContainerComponent } from './containers/region-container/region-container.component';
import { RegionMobileFilterComponent } from './components/region-mobile-filter/region-mobile-filter.component';
import { RegionDetailsContainerComponent } from './containers/region-container/region-details-container.component';
import { FacilityNotepadComponent } from './components/facility-notepad/facility-notepad.component';
import { LocationsFormComponent } from './components/locations-form/locations-form.component';
import { FacilityContactListEmbedComponent } from './components/facility-contact-list-embed/facility-contact-list-embed.component';

@NgModule({
  imports: [
    CommonModule,
    LocationsRoutingModule,
    CoreModule,
    ContactsModule,
    PickListModule,
    FacilityNotepadComponent,
    LocationsFormComponent,
    FacilityContactListEmbedComponent,
    TranslateModule.forChild({}),
  ],
  declarations: [
    LocationContainerComponent,
    LocationMobileFilterComponent,
    FacilityContainerComponent,
    FacilityDetailsContainerComponent,
    FacilityMobileFilterComponent,
    AreaContainerComponent,
    AreaDetailsContainerComponent,
    AreaMobileFilterComponent,
    RegionContainerComponent,
    RegionMobileFilterComponent,
    RegionDetailsContainerComponent,
  ],
  exports: [
    LocationContainerComponent,
    LocationMobileFilterComponent,
    FacilityContainerComponent,
    FacilityDetailsContainerComponent,
    FacilityMobileFilterComponent,
    AreaContainerComponent,
    AreaDetailsContainerComponent,
    AreaMobileFilterComponent,
    RegionContainerComponent,
    RegionMobileFilterComponent,
    RegionDetailsContainerComponent,
  ],
})
export class LocationsModule {}

@if (!entryWorking) {
<header>
  <span class="date-and-user">
    {{ entry.displayDate | date : "medium" }} - {{ entry.displayName }}
  </span>
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-fw pi-pencil"
    class="p-button-rounded p-button-text"
    (click)="editEntry(entry)"
    [disabled]="!canEditThisEntry()"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-fw pi-trash"
    class="p-button-rounded p-button-text"
    (click)="deleteEntry(entry)"
    [disabled]="!canDeleteThisEntry()"
  ></button>
</header>
<span class="category">{{ entry.Category.name }}</span>
<hr />
<main [innerHTML]="entry.note"></main>
} @else {
<app-progress-spinner [enabled]="entryWorking"></app-progress-spinner>
}

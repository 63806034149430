import { gql } from '@apollo/client/core';

export const getArea = gql`
query getArea($id: String) {
  getArea(id: $id) {
    id
    name
    contactListId
    owner {
      id
      firstName
      lastName
      nickName
    }
    region {
      id
      name
    }
    facilities {
      id
      facilityName
    }
    updater
    deletedAt
    version
  }
}`
<div id="location-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  <div id="location-action-buttons">
    <div style="text-align: right">
      <app-progress-spinner [enabled]="saving"></app-progress-spinner>
    </div>
    <div id="business-units-wrapper">
      <app-business-unit-filter
        (selectedBusinessUnitsChanged)="handleBusinessUnitChange($event)"
        #buFilter
      ></app-business-unit-filter>
    </div>
    <button
      pButton
      class="width-eighty"
      label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
      icon="fa fa-fw fa-plus-circle"
      (click)="newLocation()"
      *ngIf="canCreate === true && !isMobileDevice()"
    ></button>
    <button
      pButton
      class="refresh-button width-twenty"
      icon="fa fa-fw fa-refresh"
      (click)="refresh()"
    ></button>
    <div *ngIf="!isMobileDevice()">
      <button
        pButton
        label="{{ 'COMMON.LABEL.BUTTONS.CLEAR' | translate }}"
        icon="fa fa-fw fa-eraser"
        (click)="clearScreen()"
      ></button>
    </div>
  </div>
  <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
  <div
    [style.display]="!elements ? 'none' : ''"
    *ngIf="!ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])"
  >
    <app-general-grid
      [values]="elements"
      [columns]="columns"
      [loading]="loading"
      [deviceSize]="getDeviceSize()"
      [totalRecords]="totalRecords"
      [filterable]="true"
      [screenName]="screenName"
      [rows]="rows"
      [lazy]="true"
      (lazyLoad)="lazyLoad($event)"
      [includeExportCSV]="false"
      (changeViewEvent)="changeViewEvent($event)"
      scrollHeight="calc(100vh - 300px)"
      [usePaginator]="true"
      [rowsPerPageOptions]="[50, 100, 200]"
      #grid
    >
    </app-general-grid>
  </div>

  <p-panel
    [showHeader]="true"
    header="Filters"
    [toggleable]="true"
    [(collapsed)]="mobileFilterCollapsed"
    *ngIf="ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])"
  >
    <div>
      <div>
        <app-location-mobile-filter
          (mobileFilterEvent)="mobileFilter($event)"
        ></app-location-mobile-filter>
      </div>
    </div>
  </p-panel>
  <div
    [style.display]="!elements ? 'none' : ''"
    *ngIf="ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])"
  >
    <app-general-grid
      [values]="elements"
      [columns]="columns"
      [loading]="loading"
      [deviceSize]="getDeviceSize()"
      [totalRecords]="totalRecords"
      [filterable]="true"
      [screenName]="screenName"
      [rows]="rows"
      [lazy]="true"
      (lazyLoad)="lazyLoad($event)"
      [includeExportCSV]="false"
      (changeViewEvent)="changeViewEvent($event)"
      scrollHeight="calc(100vh - 175px)"
      [usePaginator]="true"
      [rowsPerPageOptions]="[50, 100, 200]"
      #grid
    >
    </app-general-grid>
  </div>

  <p-dialog
    header="Location Details"
    [(visible)]="displayDialog"
    showEffect="fade"
    [modal]="true"
    [transitionOptions]="'0ms'"
    [style]="{ width: '750px' }"
  >
    <form [formGroup]="locationCreateForm">
      <div id="location-create-form">
        <div style="height: 40px; margin-bottom: 8px">
          <div
            class="button-wrapper"
            style="height: 40px; margin-bottom: 8px"
            *ngIf="canEdit"
          >
            <button
              pButton
              style="margin-left: 8px"
              label="{{ 'COMMON.LABEL.BUTTONS.DELETE' | translate }}"
              icon="fa fa-fw fa-trash"
              (click)="deleteLocation()"
            ></button>
          </div>
          <div
            class="button-wrapper"
            *ngIf="(canEdit || canCreate) && isEditting === true"
          >
            <button
              pButton
              style="margin-left: 8px"
              label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
              icon="fa fa-fw fa-save"
              (click)="saveLocation()"
              [disabled]="!this.locationCreateForm.valid"
            ></button>
          </div>
          <div class="button-wrapper" *ngIf="canEdit && isEditting === false">
            <button
              pButton
              style="margin-left: 8px"
              label="{{ 'COMMON.LABEL.BUTTONS.EDIT' | translate }}"
              icon="fa fa-fw fa-edit"
              (click)="editLocation()"
            ></button>
          </div>
        </div>

        <div style="width: 100%">
          <p-pickList
            [style]="{ 'margin-bottom': '10px' }"
            [source]="sourcePicklist"
            [target]="targetPicklist"
            targetHeader="Assigned Business Units"
            [dragdrop]="false"
            sourceHeader="Available Business Units"
            [responsive]="true"
            [showSourceControls]="false"
            [showTargetControls]="false"
            [sourceStyle]="{
              height: '250px',
              'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
            }"
            [targetStyle]="{
              height: '250px',
              'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
            }"
            [disabled]="locationCreateForm.disabled"
          >
            <ng-template let-businessUnit pTemplate="item">
              <div>
                <div>
                  <h5>{{ businessUnit.name }}</h5>
                </div>
              </div>
            </ng-template>
          </p-pickList>
        </div>

        <div style="margin-bottom: 8px">
          <span class="label" style="width: 25%; margin-left: 8px"
            >{{ "LOCATION.LABEL.STATE" | translate }}:</span
          >
          <span>
            <p-autoComplete
              [suggestions]="filteredStates"
              (completeMethod)="stateSearch($event)"
              formControlName="state"
              [dropdown]="true"
              appendTo="body"
              [virtualScroll]="true"
              [virtualScrollItemSize]="34"
            ></p-autoComplete>
          </span>
        </div>
        <div style="margin-bottom: 8px">
          <span class="label" style="width: 25%; margin-left: 8px"
            >{{ "LOCATION.LABEL.COUNTY" | translate }}:</span
          >
          <span>
            <p-autoComplete
              [suggestions]="filteredCounties"
              (completeMethod)="countySearch($event)"
              formControlName="county"
              [dropdown]="true"
              field="county"
              (onSelect)="selectCounty($event)"
              appendTo="body"
              [virtualScroll]="true"
              [virtualScrollItemSize]="34"
            ></p-autoComplete>
          </span>
        </div>
        <div>
          <span class="label" style="width: 25%; margin-left: 8px"
            >{{ "LOCATION.LABEL.TOWNSHIP" | translate }}:</span
          >
          <span>
            <input pInputText formControlName="township" />
          </span>
        </div>
      </div>
    </form>
  </p-dialog>
</div>

import { Injectable } from "@angular/core";
import { isNullOrEmptyArray } from "../core/functions/common-functions";
import { Area } from "../model/locations/area";

@Injectable({
  providedIn: 'root'
})
export class AreaBuilderService {
  constructor() {}

  buildArea(item: any, facilities: any[]): Area {
  
    if (!isNullOrEmptyArray(facilities)) {
      const facIds = (item.facilities) ? item.facilities.map((f) => f.id) : [];
      const facs = (facilities) ? facilities.filter((f) => facIds.includes(f.id)) : [];
      item.facilities = facs;
    }

    const area = {
      id: item.id,
      name: item.name,
      contactListId: item.contactListId,
      owner: item.owner,
      ownerId: (item.owner) ? item.owner.id : '',
      region: (item.region) ? item.region.name : '',
      regionId: (item.regionId) ? item.regionId : (item.region) ? item.region.id : '',
      facilities: (item.facilities && item.facilities.length > 0) ? item.facilities.map((f) => ({
        id: f.id,
        facilityName: f.facilityName
      })) : [],
      updater: item.updater,
      deletedAt: item.deletedAt,
      version: item.version
    };

    return area;
  }

  buildUpdateStatement(area: Area, areaInput: any): any {
    const retVal = {
      id: area.id
    };

    if (areaInput.hasOwnProperty('name')) { retVal['name'] = areaInput.name; }
    if (areaInput.hasOwnProperty('ownerId')) { retVal['ownerId'] = areaInput.ownerId; }
    if (areaInput.hasOwnProperty('regionId')) { retVal['regionId'] = areaInput.regionId; }
    if (areaInput.hasOwnProperty('contactListId')) { retVal['contactListId'] = areaInput.contactListId; }

    return retVal;
  }
}
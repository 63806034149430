<div id="facility-details">
  <app-progress-spinner [enabled]="!facility"></app-progress-spinner>
  <p-blockUI [blocked]="saving === true || loading === true"></p-blockUI>
  <div *ngIf="facility">
    <form [formGroup]="form" id="facility-create-form">
      <div id="facility-action-buttons-form">
        <div class="button-header">
          <div>
            <app-progress-spinner [enabled]="saving"></app-progress-spinner>
          </div>

          <div class="input" *ngIf="facility?.id && actions.length > 0">
            <span class="label">{{ "COMMON.LABEL.ACTIONS" | translate }}:</span>
            <span class="align-left">
              <p-dropdown
                [options]="actions"
                (onChange)="actionEvent($event, dd)"
                [(ngModel)]="selectedAction"
                [ngModelOptions]="{ standalone: true }"
                styleClass="action-drop-down"
                #dd
              ></p-dropdown>
            </span>
          </div>

          <button
            pButton
            *ngIf="
              (canEdit ||
                canCreate ||
                canAddContactListToFacility ||
                canRemoveContactListFromFacility) &&
              isEditting &&
              !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])
            "
            label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
            icon="fa fa-fw fa-save"
            (click)="saveFacility()"
            [disabled]="this.form.invalid || saving"
          ></button>
          <button
            pButton
            *ngIf="
              canEdit &&
              isEditting === false &&
              !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])
            "
            label="{{ 'COMMON.LABEL.BUTTONS.EDIT' | translate }}"
            icon="fa fa-fw fa-save"
            (click)="setEditting(true)"
          ></button>
        </div>
        <hr id="facility-scroll-divider" />
        <div>
          <div class="row details-row-1">
            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.FACILITY_NAME" | translate }}:<span
                  class="required"
                  >*</span
                ></span
              >
              <span class="row-input">
                <input
                  type="text"
                  pInputText
                  class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input"
                  formControlName="facilityName"
                />
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.FACILITY_UID" | translate }}:<span
                  class="required"
                  >*</span
                ></span
              >
              <span class="row-input">
                <input
                  type="text"
                  pInputText
                  class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input"
                  formControlName="facilityUid"
                  size="10"
                />
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.FLOC" | translate }}:</span
              >
              <span class="row-input">
                <input
                  type="text"
                  pInputText
                  class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input"
                  formControlName="flocNumber"
                />
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.FACILITY_TYPE" | translate }}:<span
                  class="required"
                  >*</span
                ></span
              >
              <span class="row-input">
                <p-dropdown
                  [options]="types"
                  formControlName="facilityType"
                  [style]="{ width: '99%' }"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="30"
                >
                </p-dropdown>
              </span>
            </div>
          </div>

          <div class="row details-row-2">
            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.AOR" | translate }}:<span class="required"
                  >*</span
                ></span
              >
              <span class="row-input ui-fluid">
                <p-dropdown
                  [options]="availableDistricts"
                  formControlName="aor"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="30"
                  styleClass="wide-aor-dropdown"
                >
                </p-dropdown>
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.REGION" | translate }}:</span
              >
              <span class="row-input">
                <p-autoComplete
                  [suggestions]="filteredRegions"
                  formControlName="region"
                  (completeMethod)="regionSearch($event)"
                  (onSelect)="selectRegion($event)"
                  [dropdown]="true"
                  field="label"
                  appendTo="body"
                ></p-autoComplete>
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.AREA" | translate }}:</span
              >
              <span class="row-input">
                <p-autoComplete
                  [suggestions]="filteredAreas"
                  (completeMethod)="areaSearch($event)"
                  (onSelect)="areaSelected($event)"
                  formControlName="area"
                  [dropdown]="true"
                  field="label"
                  appendTo="body"
                ></p-autoComplete>
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.PRIMARY_EMPLOYEE" | translate }}:</span
              >
              <span class="row-input">
                <p-autoComplete
                  [suggestions]="filteredContacts"
                  (completeMethod)="contactSearch($event)"
                  formControlName="owner"
                  [dropdown]="true"
                  field="label"
                  appendTo="body"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="34"
                >
                </p-autoComplete>
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.PHONE" | translate }}:</span
              >
              <span
                class="row-input"
                [style]="{ 'min-width': 'calc(100% - 2vw)' }"
              >
                <p-inputMask
                  mask="999-999-9999"
                  id="phone"
                  formControlName="phone"
                  (onBlur)="fixMask('phone')"
                  [size]="12"
                >
                </p-inputMask>
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.ALT_PHONE" | translate }}:</span
              >
              <span
                class="row-input"
                [style]="{ 'min-width': 'calc(100% - 2vw)' }"
              >
                <p-inputMask
                  mask="999-999-9999"
                  id="phone"
                  formControlName="altPhone"
                  (onBlur)="fixMask('altPhone')"
                  [size]="12"
                >
                </p-inputMask>
              </span>
            </div>
          </div>

          <div class="row details-row-3">
            <div class="row-item" (click)="toggleLocationsDialog('State')">
              <span class="label"
                >{{ "LOCATION.LABEL.STATE" | translate }}:</span
              >
              <span class="row-input" style="cursor: pointer">
                <p [style]="{ display: 'table-cell' }">
                  {{ getStateListForDisplay() }}
                </p>
              </span>
            </div>

            <div class="row-item" (click)="toggleLocationsDialog('County')">
              <span class="label"
                >{{ "LOCATION.LABEL.COUNTY" | translate }}:</span
              >
              <span class="row-input" style="cursor: pointer">
                <p [style]="{ display: 'table-cell' }">
                  {{ getCountyListForDisplay() }}
                </p>
              </span>
            </div>

            <div class="row-item" (click)="toggleLocationsDialog('Township')">
              <span class="label"
                >{{ "LOCATION.LABEL.TOWNSHIP" | translate }}:</span
              >
              <span class="row-input" style="cursor: pointer">
                <p [style]="{ display: 'table-cell' }">
                  {{ getTownshipListForDisplay() }}
                </p>
              </span>
            </div>

            <div class="row-item">
              <span class="label">{{ "LOCATION.LABEL.GPS" | translate }}:</span>
              <span class="row-input">
                <input
                  type="text"
                  pInputText
                  class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input"
                  formControlName="gps"
                />
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.ADDRESS" | translate }}:</span
              >
              <span class="row-input">
                <input
                  type="text"
                  pInputText
                  class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input"
                  formControlName="address"
                />
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.LOCAL_TIME" | translate }}:</span
              >
              <span class="row-input">
                <p [style]="{ display: 'table-cell' }">{{ localTime }}</p>
              </span>
            </div>
          </div>

          <div class="row details-row-4" *ngIf="isEditting">
            <div class="row-item">
              <span class="label"
                >{{ "COMMON.LABEL.BUSINESS_UNITS" | translate }}:<span
                  class="required"
                  >*</span
                ></span
              >
              <span class="row-input">
                <p-dropdown
                  [options]="availableBusinessUnits"
                  formControlName="businessUnit"
                  size="4"
                  [style]="{ width: 'inherit' }"
                >
                </p-dropdown>
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.COMPANY" | translate }}:<span
                  class="required"
                  >*</span
                ></span
              >
              <span class="row-input" id="company-input">
                <p-dropdown
                  [options]="availableCompanies"
                  formControlName="company"
                  [style]="{ width: '99%' }"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="30"
                >
                </p-dropdown>
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.TIMEZONE" | translate }}:</span
              >
              <span class="row-input">
                <p-dropdown
                  formControlName="timezone"
                  [options]="timeZones"
                  optionLabel="label"
                  [filter]="true"
                  filterBy="label"
                  (onChange)="onTimeZoneChange($event)"
                  appendTo="body"
                  [style]="{ width: '100%' }"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="30"
                ></p-dropdown>
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.CONTACT_LIST" | translate }}:</span
              >
              <span class="row-input">
                <p-dropdown
                  [options]="contactListsForDropdowns"
                  optionLabel="name"
                  optionValue="id"
                  [filter]="true"
                  filterBy="name"
                  formControlName="ContactListId"
                  appendTo="body"
                  [virtualScroll]="true"
                  [itemSize]="34"
                ></p-dropdown>
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{
                  "LOCATION.LABEL.MANAGEMENT_CONTACT_LIST" | translate
                }}:</span
              >
              <span class="row-input">
                <p-dropdown
                  [options]="contactListsForDropdowns"
                  optionLabel="name"
                  optionValue="id"
                  [filter]="true"
                  filterBy="name"
                  formControlName="managementContactListId"
                  appendTo="body"
                  [virtualScroll]="true"
                  [itemSize]="34"
                ></p-dropdown>
              </span>
            </div>

            <div class="row-item">
              <span class="label"
                >{{ "LOCATION.LABEL.SCADA_ID" | translate }}:</span
              >
              <span class="row-input">
                <input
                  type="text"
                  pInputText
                  class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input"
                  formControlName="scadaId"
                  size="10"
                />
              </span>
            </div>
          </div>

          <div class="row">
            <div class="row-item-long">
              <span class="label" id="notes-label"
                >{{ "LOCATION.LABEL.NOTES" | translate }}:</span
              >
              <span class="row-input">
                <textarea
                  pInputTextarea
                  autoResize="autoResize"
                  class="ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input"
                  formControlName="notes"
                ></textarea>
              </span>
            </div>
          </div>
        </div>

        <div id="facility-tab-view">
          <p-tabView [activeIndex]="tabIndex" (onChange)="tabChanged($event)">
            <p-tabPanel header="CONTACT LIST" class="tab-panel">
              @if (contactList) {
              <app-facility-contact-list-embed
                [contactList]="contactList"
                [managementContactList]="managementContactList"
              ></app-facility-contact-list-embed>
              } @else { No contact list is associated with this Facility. }
            </p-tabPanel>
            <p-tabPanel header="NOTEPAD" class="tab-panel">
              <app-facility-notepad
                [facility]="facility"
                [archivedStatus]="ArchivedEntryStatus.NonArchivedOnly"
                (notepadChangedEvent)="onNotepadChange()"
              ></app-facility-notepad>
            </p-tabPanel>
            <p-tabPanel header="NOTEPAD ARCHIVE" class="tab-panel">
              <app-facility-notepad
                [facility]="facility"
                [archivedStatus]="ArchivedEntryStatus.ArchivedOnly"
                (notepadChangedEvent)="onNotepadChange()"
              ></app-facility-notepad>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    </form>
  </div>

  <app-audit-dialog-container
    *ngIf="displayAuditDialog"
    [displayDialog]="displayAuditDialog"
    [auditHistory]="history"
    (dialogHiddenEvent)="auditDialogHidden()"
    [loading]="auditLoading"
  ></app-audit-dialog-container>

  <app-locations-form
    *ngIf="facility && states && counties && townships"
    [states]="states"
    [counties]="counties"
    [townships]="townships"
    [isVisible]="displayLocationsDialog"
    [facility]="facility"
    formTitle="Manage Locations"
    [locationType]="locationDialogType"
    (locationsHidden)="onLocationsDialogHidden()"
    (locationsUpdated)="onLocationsDialogUpdated($event)"
    #locationsForm
  ></app-locations-form>
</div>

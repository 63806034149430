<p-dialog
  header="{{ formTitle }}"
  [(visible)]="isVisible"
  (visibleChange)="loadLocations(false)"
  showEffect="fade"
  [modal]="true"
  [transitionOptions]="'0ms'"
  [style]="{ width: '50vw' }"
  appendTo="body"
  (onHide)="onDialogHide()"
  [dismissableMask]="true"
>
  <app-progress-spinner [enabled]="loading"></app-progress-spinner>
  @if(!loading) {
  <form #locationsForm [formGroup]="manageLocationsForm">
    <div class="container">
      <p-pickList
        [source]="sourcePicklist"
        [target]="targetPicklist"
        targetHeader="Assigned {{ getPluralLabel() }}"
        [dragdrop]="false"
        sourceHeader="Available {{ getPluralLabel() }}"
        [responsive]="true"
        [showSourceControls]="false"
        [showTargetControls]="false"
        [sourceStyle]="{
          height: '400px',
          'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
        }"
        [targetStyle]="{
          height: '400px',
          'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
        }"
        filterBy="label,county,township"
        #pickList
      >
        <ng-template let-availableItem pTemplate="item">
          <div>
            <div>
              @if (locationType === 'State') {
              <h5>{{ availableItem.label }}</h5>
              } @if (locationType === 'County') {
              <h5>{{ availableItem.county }}</h5>
              } @if (locationType === 'Township') {
              <h5>{{ availableItem.township }}</h5>
              }
            </div>
          </div>
        </ng-template>
      </p-pickList>
      <div class="button-wrapper">
        <button
          pButton
          label="{{ 'COMMON.LABEL.BUTTONS.UPDATE' | translate }}"
          (click)="updateLocations()"
        ></button>
      </div>
    </div>
  </form>
  }
</p-dialog>

import gql from "graphql-tag";

export const queryForAreas = gql`
query queryForAreas($limit: Int, $page: Int, $query: AreaSortOrQuery, $sort: AreaSortOrQuery) {
  queryForAreas(limit: $limit, page: $page, query: $query, sort: $sort) {
    items {
      id
      name
      region
      contactList
      owner
      facilities
    }
    totalRecords
  }
}`;
import gql from "graphql-tag";

export const queryForRegions = gql`
query queryForRegions($limit: Int, $page: Int, $query: RegionSortOrQuery, $sort: RegionSortOrQuery) {
  queryForRegions(limit: $limit, page: $page, query: $query, sort: $sort) {
    items {
      id
      name
      contactList
      owner
      areas
    }
    totalRecords
  }
}`;
import { Injectable } from "@angular/core";
import { isNullOrEmptyArray } from "../core/functions/common-functions";
import { Region } from "../model/locations/region";

@Injectable({
  providedIn: 'root'
})
export class RegionBuilderService {
  constructor() {}

  buildRegion(item: any, areas: any[]): Region {
  
    if (!isNullOrEmptyArray(areas)) {
      const areaIds = (item.areas) ? item.areas.map((f) => f.id) : [];
      const ars = (areas) ? areas.filter((f) => areaIds.includes(f.id)) : [];
      item.areas = ars;
    }

    const region = {
      id: item.id,
      name: item.name,
      contactListId: item.contactListId,
      owner: item.owner,
      ownerId: (item.owner) ? item.owner.id : '',
      areas: (item.areas && item.areas.length > 0) ? item.areas.map((f) => ({
        id: f.id,
        name: f.name
      })) : [],
      updater: item.updater,
      deletedAt: item.deletedAt,
      version: item.version
    };

    return region;
  }

  buildUpdateStatement(region: Region, regionInput: any): any {
    const retVal = {
      id: region.id
    };

    if (regionInput.hasOwnProperty('name')) { retVal['name'] = regionInput.name; }
    if (regionInput.hasOwnProperty('ownerId')) { retVal['ownerId'] = regionInput.ownerId; }
    if (regionInput.hasOwnProperty('contactListId')) { retVal['contactListId'] = regionInput.contactListId; }

    return retVal;
  }
}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LocationContainerComponent } from './containers/location-container/location-container.component';
import { FacilityContainerComponent } from './containers/facility-container/facility-container.component';
import { AuthGuard } from '../guards/auth.guard';
import { FacilityDetailsContainerComponent } from './containers/facility-container/facility-details-container.component';
import { CanDeactivateGuard } from '../guards/deactivate.guard';
import { AreaContainerComponent } from './containers/area-container/area-container.component';
import { AreaDetailsContainerComponent } from './containers/area-container/area-details-container.component';
import { RegionContainerComponent } from './containers/region-container/region-container.component';
import { RegionDetailsContainerComponent } from './containers/region-container/region-details-container.component';

const routes: Routes = [
  { path: 'location', component: LocationContainerComponent, canActivate: [AuthGuard] },
  { path: 'facility', component: FacilityContainerComponent, canActivate: [AuthGuard] },
  { path: 'facility/details/:id', component: FacilityDetailsContainerComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'facility/details', component: FacilityDetailsContainerComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'area', component: AreaContainerComponent, canActivate: [AuthGuard] },
  { path: 'area/details/:id', component: AreaDetailsContainerComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'area/details', component: AreaDetailsContainerComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'region', component: RegionContainerComponent, canActivate: [AuthGuard] },
  { path: 'region/details/:id', component: RegionDetailsContainerComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'region/details', component: RegionDetailsContainerComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LocationsRoutingModule { }

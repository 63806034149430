import { Column } from "src/app/model/common/column";

export const REGION_TABLE_DEFINITION: Column[] = [
    {
        field: 'name',
        header: 'LOCATION.LABEL.REGION',
        mobileHeader: 'LOCATION.LABEL.REGION',
        formatPattern: 'text',
        width: '20%',
        sortable: true,
        filterable: true,
        matchMode: 'contains'
    }, {
      field: 'owner',
      header: 'LOCATION.LABEL.OWNER',
      mobileHeader: 'LOCATION.LABEL.OWNER',
      formatPattern: 'text',
      width: '20%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    }, {
        field: 'contactList',
        header: 'LOCATION.LABEL.CONTACT_LIST',
        mobileHeader: 'LOCATION.LABEL.CONTACT_LIST',
        formatPattern: 'text',
        width: '20%',
        sortable: true,
        filterable: true,
        matchMode: 'contains'
    }, {
        field: 'areas',
        header: 'LOCATION.LABEL.AREAS',
        mobileHeader: 'LOCATION.LABEL.AREAS',
        formatPattern: 'text',
        width: '20%',
        sortable: true,
        filterable: true,
        matchMode: 'contains'
    }
]